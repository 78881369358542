import React, { useState, useEffect } from "react";
import FormsNew from "./FormsNew";
import ErrorPopup from "../../utilities/popup/ErrorPopup";
import { toast, ToastContainer } from 'react-toastify';
import { APICALL as AXIOS } from "../../services/AxiosServices"
import { FlexSalaryCreateOrUpdateApiUrl, UndoFlexSalaryApiUrl } from "../../routes/ApiEndPoints"
import { t } from "../../translations/Translation";
import CustomPopup from "../../utilities/popup/CustomPopup";
import DateInput from "../atoms/formFields/DateInput";
import ModalPopup from "../../utilities/popup/Popup";

export default function AddFlexSalary() {

    const [formData, setFormData] = useState({
        "minimum_salary": ""
    });
    const [errors, setErrors] = useState([]);
    const [Success, setSuccess] = useState(true);
    const [showDatePopup, setShowDatePopup] = useState(false);
    const [date, setDate] = useState("");
    const [cache, setCache] = useState("")
    const [refresh, setRefresh] = useState(false)
    const [displaySave, setDisplaySave] = useState(false)
    const [warningMessage, setWarningMessage] = useState("")

    useEffect(() => {
        AXIOS.service(FlexSalaryCreateOrUpdateApiUrl + '/flex_min_salary', 'GET')
            .then((result) => {
                if (result?.success) {
                    let data = { "minimum_salary": result?.data?.europian_format }
                    setFormData(data)
                    setCache(data)
                    setDisplaySave(false)
                }
            });
    }, [refresh])

    const setValues = (index, name, value, field) => {
        let newData = { ...formData }
        newData['minimum_salary'] = value;
        setFormData(newData);

        if (cache.minimum_salary === value) {
            setDisplaySave(prev => false)
        } else {
            setDisplaySave(prev => true)
        }
    }

    const fieldsArray = [
        { title: t('FLEX_SALARY'), name: 'minimum_salary', required: true, type: 'text', style: 'col-md-6 mt-4 float-left' },
    ];

    const onSave = (data) => {

        AXIOS.service(FlexSalaryCreateOrUpdateApiUrl, 'POST', data)
            .then((result) => {
                if (result?.success) {
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setShowDatePopup(false)
                    setDate("")
                    setSuccess(true)
                    setRefresh(!refresh)

                } else {
                    setErrors(result.message);
                    setSuccess(true)

                }
            })
            .catch((error) => {
                console.log(error);
                setSuccess(true)

            })
    }

    const SaveSalaries = () => {
        if (cache?.minimum_salary !== formData?.minimum_salary) {
            setShowDatePopup(true)
        } else {
            onSave(formData);
        }
    }

    const onConfirm = () => {
        formData.date = date
        onSave(formData);
    }

    const undoSalaries = () => {
        AXIOS.service(UndoFlexSalaryApiUrl, "GET")
            .then((result) => {
                if (result.success) {
                    setWarningMessage("")
                    setRefresh(!refresh)
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    setErrors(result.message)
                }
            })
            .catch((error) => {
                console.log(error);

            })
    }

    return (
        <div className="right-container add_public_holidays">
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            {warningMessage && <ModalPopup
                title={t("WARNING_TITLE")}
                body={(warningMessage)}
                onConfirm={undoSalaries}
                onHide={() => setWarningMessage('')}
            ></ModalPopup>}
            {showDatePopup && <CustomPopup
                title={t("CHANGES_SHOULD_BE_APPLIED_FROM")}
                header
                footer
                close
                size="m"
                saveButtonName={t("SAVE")}
                onHide={() => { setShowDatePopup(false); setDate("") }}
                onConfirm={() => onConfirm()}
            >
                <DateInput
                    title={t("DATE")}
                    name={"date"} There
                    required={true}
                    CustomStyle={"p-4"}
                    value={date}
                    setValue={setDate}
                    placeholder={"date"}
                ></DateInput>
            </CustomPopup>}
            {errors !== undefined && errors.length !== 0 && <ErrorPopup
                title={t("VALIDATION_ERROR") + ("!")}
                body={(errors)}
                onHide={() => setErrors([])}
            ></ErrorPopup>}
            <FormsNew
                view="flex salary"
                formTitle={t("ADD_FLEX_SALARY")}
                redirectURL={'/manage-configurations/min_salary'}
                formattedData={formData}
                data={fieldsArray}
                SetValues={setValues}
                OnSave={SaveSalaries}
                setSuccess={setSuccess}
                Success={Success}
                displaySave={displaySave}
                onUndo={() => setWarningMessage(t("CONFIRMATION_SLARIES_REVERT_BACK"))}
            ></FormsNew>
        </div >
    );
}