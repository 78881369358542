import React, { useEffect, useState } from "react";
import TimeIcon from "../../static/icons/Time.svg";
import CostIcon from "../../static/icons/Euro.svg";
import ContractHoursIcon from "../../static/icons/Contract.svg";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import { GetAvailabilitiesApiUrl } from "../../routes/ApiEndPoints";
import EmployeeType_icon from "../../static/icons/EmployeeType_icon";
import Dropdown from "../atoms/Dropdown";
import LeaveIcon from "../../static/icons/addLeave.svg";
import FlagIcon from "../../static/icons/belgium.svg";
import AddLeavePopup from "./AddLeavePopup";
import { t } from "../../translations/Translation";
import RemarkIcon from "../../static/icons/exclamation-mark.png";
import { Tooltip as ReactTooltip } from "react-tooltip";



export default function PlanItem({ PlansData, Dates, openCreatePlanPopup, wid, ws_emp_index, weekNumber, year, data, availableSwitch, ws_employee, employeeList, setDataRefresh, dataRefresh }) {


    // const [data, setData] = useState([])
    const [employee, setEmployee] = useState();
    const [leaveData, setLeaveData] = useState({});
    const [leavePopup, setLeavePopup] = useState(false);
    const UserPermissions = JSON.parse(localStorage.getItem('permissions')) || [];
    // const [showSentence, setShowSentence] = useState(false);

    const availableStyle = "py-2 px-0 height-10 planning-position shadow-sm background-green"
    const unAvailableStyle = "py-2 px-0 height-10 planning-position shadow-sm background-red"
    const style = "py-2 px-0 height-10 planning-position shadow-sm"
    const leave = UserPermissions?.includes('view_leaves') ? ("py-2 px-0 height-10 planning-position shadow-sm bg-red") : ""
    const pendingLeave = UserPermissions?.includes('view_leaves') ? ("py-2 px-0 height-10 planning-position shadow-sm bg-warning") : ""


    // useEffect(() => {
    //     let request_data = {
    //         "week": weekNumber,
    //         "year": year,
    //     }
    //     if (availableSwitch) {
    //         AXIOS.service(GetAvailabilitiesApiUrl, 'POST', request_data)
    //             .then((result) => {
    //                 if (result?.success) {
    //                     setData(result.data[ws_employee.employee_id ? ws_employee.employee_id : employee]);
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //             })
    //     } else {
    //         setData([])
    //     }
    // }, [availableSwitch, weekNumber, year])

    // useEffect(() => {
    //     let request_data = {
    //         "week": weekNumber,
    //         "year": year,
    //         "employee_profile_id": employee ? employee.value : ws_employee.employee_id,
    //     }
    //     AXIOS.service(GetEmployeeLeavesApiUrl, 'POST', request_data)
    //         .then((result) => {
    //             if (result?.success) {
    //                 setLeaveData(result.data)
    //                 // setData(result.data[ws_employee.employee_id ? ws_employee.employee_id : employee]);
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         })
    //     // } else {
    //     //     setData([])

    // }, [employee, weekNumber, year])


    const openPopup = (e, employee_id, date, wid, PlansData, ws_emp_index) => {
        if (e.target.tagName !== 'IMG') {
            openCreatePlanPopup(employee_id, date, wid, PlansData, ws_emp_index)
        } else {
            if (PlansData[date]?.absences[0]?.can_edit) {
                setLeavePopup(true);
                setLeaveData(PlansData[date]?.absences[0]);
            }
        }
    }



    return (
        <>
            {leavePopup && UserPermissions?.includes('modify_leaves') && <AddLeavePopup buttonName={t("CANCEL")} setAddLeave={setLeavePopup} addLeave={leavePopup} setDataRefresh={setDataRefresh} dataRefresh={dataRefresh} updateLeaveData={leaveData}></AddLeavePopup>}
            <td>{ws_employee.employee_id ? <a className="text-dark text-truncate plannign_overview_weekly_employee_title" href={"/manage-employees/" + ws_employee.employee_id} title={ws_employee.employee_name}>{ws_employee.employee_name}</a> : ws_employee.employee_name}

                {ws_employee.status === undefined ? <div> {ws_employee.employee_id && ws_employee.employee_types !== null && ws_employee.employee_types !== undefined && Object.keys(ws_employee.employee_types).length !== 0 &&
                    //mapping employeetype and its colour
                    Object.keys(ws_employee.employee_types).map((key, index) => {
                        return (
                            <span key={index} data-tooltip-id={key}><EmployeeType_icon IconColour={ws_employee.employee_types[key] ? ws_employee.employee_types[key] : " #61bfb5"} />
                                <ReactTooltip
                                    id={key}
                                    place="top"
                                    globalEventOff="click"
                                    content={key}
                                    style={{ zIndex: 9999 }}
                                />
                            </span>
                        )
                    })
                }</div> :
                    <Dropdown options={employeeList} onSelectFunction={(e) => setEmployee(e.value)}></Dropdown>}
            </td>

            {Dates.map((date) => {
                return (
                    <td key={date} className={data[ws_employee.employee_id ? ws_employee.employee_id : employee]?.available?.includes(date) ? availableStyle : data[ws_employee.employee_id ? ws_employee.employee_id : employee]?.not_available?.includes(date) ? unAvailableStyle : PlansData[date]?.absences && PlansData[date]?.absences?.length === 0 ? style : (PlansData[date]?.absences[0]?.absence_status === "approved" ? leave : PlansData[date]?.absences[0]?.absence_status === "pending" ? pendingLeave : "")} onClick={(e) => openPopup(e, ws_employee.employee_id ? ws_employee.employee_id : employee, date, wid, PlansData, ws_emp_index)}>
                        {data[ws_employee.employee_id ? ws_employee.employee_id : employee]?.remarks[date] !== undefined && <img className='m-0 p-0 planning-remark-icon h-0' data-tooltip-id={(data[ws_employee.employee_id ? ws_employee.employee_id : employee]?.remarks[date]) + date} src={RemarkIcon} alt={t("ICON")} ></img>}
                        <ReactTooltip
                            id={(data[ws_employee.employee_id ? ws_employee.employee_id : employee]?.remarks[date]) + date}
                            place="top"
                            globalEventOff="click"
                            content={data[ws_employee.employee_id ? ws_employee.employee_id : employee]?.remarks[date]}
                            style={{ zIndex: 9999 }}
                        />

                        {PlansData[date] && <>
                            {PlansData[date]['planning'] !== undefined && PlansData[date]['planning'].map((plan) => {
                                return (
                                    <small key={plan.plan_id} className={plan.timings && "row m-0 pb-1 px-2 border-bottom"}>
                                        {plan.timings && <img src={TimeIcon} className="plan-icon mr-2"></img>}
                                        {plan.timings}
                                    </small>
                                )
                            })}
                            <div className="d-flex mt-3 justify-content-between px-2">
                                {PlansData[date].cost !== undefined && PlansData[date].cost !== null && <small className="cost-position">
                                    <img src={CostIcon} className="plan-icon mr-1"></img>
                                    {PlansData[date].cost}
                                </small>}
                                {/* Leave data */}
                                {UserPermissions?.includes('view_leaves') && PlansData[date]?.absences && PlansData[date]?.absences?.length !== 0 && <img className="my-auto header-icon leave-position" src={LeaveIcon} onClick={(e) => openPopup(e, '', date, '', PlansData, '')}></img>}
                                {/* {PlansData[date] && PlansData[date]?.public_holiday?.length !== 0 && <img className="my-auto header-icon leave-position" src={FlagIcon}></img>} */}

                                {PlansData[date].contract_hours !== undefined && PlansData[date].contract_hours !== null && <small className="contract-hours-pos">
                                    <img src={ContractHoursIcon} className="plan-icon mr-1"></img>
                                    {PlansData[date].contract_hours}
                                </small>}
                            </div>
                        </>}
                    </td>
                )
            })}
        </>
    )
}
