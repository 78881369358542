export default function NumberView({ cell }) {
  return (
    <input
      type="text"
      value={getValue(cell)}
      // disabled
      disabled={cell?.plan_id ? false : true}
      style={{ pointerEvents: "none", width: '100%', border: 'none', textAlign: 'center' }}
      className={cell?.plan_id ? "mx-2" : "read-only mx-2"}
    />
  );
}

// Define a getValue function
const getValue = (cell) => {
  // Check if the cell object exists and has a value property
  if (cell && typeof cell === 'object' && 'value' in cell) {
    return cell.value; // Return the value of the cell
  }
  return null; // Return null if the cell object is invalid or doesn't have a value property
};
