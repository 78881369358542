import { useEffect, useState } from 'react';
import { t } from '../../translations/Translation'
export default function PageNotFound() {
    const [location, setLocation]= useState("/")

    useEffect(()=>{
        setLocation(window.location.pathname)
    },[])
    
    return (
        <div className="right-container">
            <div className='border company-tab-width mt-3 bg-white d-flex align-content-center justify-content-center'>
                <div className='d-flex.column col-6 p-4'>
                {location==='/'?"":<h2 className='mt-5 text-center text-danger' >{`${t("PAGE_NOT_FOUND")}...!`}</h2>}
                </div>
            </div>
        </div>
    )
}