// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 275px;
  margin-left: 36%;
  margin-top: 4%;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,4CAA4C;EAC5C,aAAa;EACb,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,OAAO,uBAAuB,EAAE;EAChC,KAAK,yBAAyB,EAAE;AAClC","sourcesContent":[".App-logo {\n  animation: App-logo-spin infinite 20s linear;\n  height: 275px;\n  margin-left: 36%;\n  margin-top: 4%;\n}\n\n@keyframes App-logo-spin {\n  from { transform: rotate(0deg); }\n  to { transform: rotate(360deg); }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
