export default function NumberEdit({ cell, onChange }) {
  return (
    <input
        type="text"
        onChange={(e) => {
          const re = /^-?\d*\.?\d*$/;
          if (e.target.value === "" || re.test(e.target.value)) {
            onChange({ ...cell, value: e.target.value });
            cell.setIndex(cell.index)
          }
        }}
        value={getValue(cell)}
        // autoFocus
        style={{ width: '100%' }}

      />
  )
}


// Define a getValue function
const getValue = (cell) => {
  // Check if the cell object exists and has a value property
  if (cell && typeof cell === 'object' && 'value' in cell) {
    return cell.value; // Return the value of the cell
  }
  return null; // Return null if the cell object is invalid or doesn't have a value property
};
