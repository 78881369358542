import React, { useEffect, useRef, useState } from "react";
import Autosuggest from 'react-autosuggest';
import { useNavigate } from "react-router-dom";
import { t } from "../../translations/Translation";
import { checkPermission } from "../../utilities/CommonFunctions";


export default function AutoSuggestion({ setSearchBar, searchBar }) {

    const permissionsArr = JSON.parse(localStorage.getItem("permissions") || "[]")
    const [suggestions, setSuggestions] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const navigate = useNavigate();
    const [value, setValue] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    useEffect(() => {
        let suggestions_arr = [
            //Dashboard
            { name: t('DASHBOARD'), url: '/' },
            checkPermission(permissionsArr, ['superadmin', 'planning', 'planning_overview', 'modify_planning', 'dimona_overview', 'holiday_overview']) ? { name: t('PLANNING'), url: '/manage-plannings' } : {},
            checkPermission(permissionsArr, ['superadmin', 'timetable', 'uurrooster_employee_cost']) ? { name: t('TIME_TABLE'), url: '/uurrooster' } : {},
            checkPermission(permissionsArr, ['superadmin', 'moderator', 'employees', 'employee_availability']) ? { name: t('AVAILABILITY'), url: '/manage-employees#employee_availability' } : {},
            checkPermission(permissionsArr, ['superadmin', 'add_employee']) ? { name: t('NEW_EMPLOYEE'), url: '/add-employees' } : {},
            checkPermission(permissionsArr, ['superadmin', 'moderator', 'employees', 'planning', 'add_employee', 'dimona_overview', 'timetable', 'download_qr_code', 'activate_device', 'employee_availability', 'holiday_overview', 'invite_employee']) ? { name: t('QR_CONFIGURATION'), url: '/' } : {},
            checkPermission(permissionsArr, ['superadmin', 'planning', 'planning_overview', 'modify_planning', 'dimona_overview', 'holiday_overview']) ? { name: t('HOLIDAY_LEAVE'), url: '/manage-plannings#holiday' } : {},
            checkPermission(permissionsArr, ['superadmin', 'planning', 'planning_overview', 'modify_planning', 'dimona_overview', 'holiday_overview']) ? { name: t('DIMONA_OVERVIEW'), url: '/manage-plannings#dimona' } : {},
            checkPermission(permissionsArr, ['superadmin', 'moderator', 'employees', 'planning', 'add_employee', 'dimona_overview', 'timetable', 'download_qr_code', 'activate_device', 'employee_availability', 'holiday_overview', 'invite_employee']) ? { name: t('DEVICE_ACTIVATE'), url: '/' } : {},
            checkPermission(permissionsArr, ['superadmin', 'invite_employee']) ? { name: t('INVITE_EMPLOYEE_OVERVIEW'), url: '/manage-invites' } : {},

            //Sidebar
            checkPermission(permissionsArr, ['superadmin', 'employees']) ? { name: t('EMPLOYEES'), url: '/manage-employees' } : {},
            checkPermission(permissionsArr, ['superadmin', 'companies']) ? { name: t('COMPANIES'), url: '/manage-companies' } : {},
            checkPermission(permissionsArr, ['current_plan']) ? { name: t('CURRENT_PLAN'), url: '/current-plan' } : {},
            checkPermission(permissionsArr, ['superadmin', 'configuration']) ? { name: t('CONFIGURATIONS'), url: '/configurations' } : {},
            checkPermission(permissionsArr, ['superadmin', 'settings']) ? { name: t('SETTINGS'), url: '/settings' } : {},
            checkPermission(permissionsArr, ['superadmin', 'reporting']) ? { name: t('REPORTING'), url: '/reporting' } : {},
            checkPermission(permissionsArr, ['superadmin', 'monthly_overview']) ? { name: t('MONTHLY_OVERVIEW'), url: '/monthly-overview' } : {},

            //Planning
            checkPermission(permissionsArr, ['superadmin', 'planning', 'planning_overview', 'modify_planning', 'dimona_overview', 'holiday_overview']) ? { name: t('PLANNING_LOGS'), url: '/manage-plannings#logs' } : {},
            checkPermission(permissionsArr, ['superadmin', 'planning', 'planning_overview', 'modify_planning', 'dimona_overview', 'holiday_overview']) ? { name: t('EVENT_PLANNING'), url: '/manage-plannings#event' } : {},
            checkPermission(permissionsArr, ['superadmin', 'planning', 'planning_overview', 'modify_planning', 'dimona_overview', 'holiday_overview']) ? { name: t('OPEN_SHIFT'), url: '/manage-plannings#open_shift' } : {},
            checkPermission(permissionsArr, ['superadmin', 'planning', 'planning_overview', 'modify_planning', 'dimona_overview', 'holiday_overview']) ? { name: t('OTH_PLANNING'), url: '/manage-plannings#oth' } : {},

            //Plan tab
            checkPermission(permissionsArr, ['superadmin', 'planning', 'planning_overview', 'modify_planning', 'dimona_overview', 'holiday_overview']) ? { name: t('SEND_DIMONA'), url: '/manage-plannings' } : {},
            checkPermission(permissionsArr, ['superadmin', 'planning', 'planning_overview', 'modify_planning', 'dimona_overview', 'holiday_overview']) ? { name: t('IMPORT_PLANNING'), url: '/manage-plannings' } : {},
            checkPermission(permissionsArr, ['superadmin', 'planning', 'planning_overview', 'modify_planning', 'dimona_overview', 'holiday_overview']) ? { name: t('ADD_LEAVE'), url: '/manage-plannings' } : {},
            checkPermission(permissionsArr, ['superadmin', 'planning', 'planning_overview', 'modify_planning', 'dimona_overview', 'holiday_overview']) ? { name: t('CLONE_PLANNING'), url: '/manage-plannings' } : {},

            //Employees
            checkPermission(permissionsArr, ['superadmin', 'employees']) ? { name: t('IMPORT_EMPLOYEES'), url: '/import-employees' } : {},

            //Companies
            checkPermission(permissionsArr, ['superadmin', 'create_company']) ? { name: t('CREATE') + " " + t('COMPANY'), url: '/manage-companies/company/0' } : {},
            checkPermission(permissionsArr, ['superadmin', 'create_company', 'companies']) ? { name: t('LOCATIONS'), url: '/manage-companies#location' } : {},
            checkPermission(permissionsArr, ['superadmin', 'create_company', 'companies']) ? { name: t('WORKSTATION'), url: '/manage-companies#workstation' } : {},
            checkPermission(permissionsArr, ['superadmin', 'create_company', 'companies']) ? { name: t('RESP_PERSON'), url: '/manage-companies#responsible_person' } : {},
            checkPermission(permissionsArr, ['superadmin', 'create_company', 'companies']) ? { name: t('COST_CENTER'), url: '/manage-companies#cost_center' } : {},
            checkPermission(permissionsArr, ['superadmin', 'create_company', 'companies']) ? { name: t('COMPANY_CONTRACTS'), url: '/manage-companies#company_contract' } : {},

            //Configurations
            checkPermission(permissionsArr, ['superadmin']) ? { name: t('CONTRACT_TYPE'), url: '/manage-configurations/contract_type' } : {},
            checkPermission(permissionsArr, ['superadmin']) ? { name: t('ADD_CONTRACT'), url: '/add-contract-type' } : {},

            checkPermission(permissionsArr, ['superadmin']) ? { name: t('EMPLOYEE_TYPES'), url: '/manage-configurations/employee_type' } : {},
            checkPermission(permissionsArr, ['superadmin']) ? { name: t('ADD_EMPLOYEE_TYPE'), url: '/add-employee-type' } : {},

            checkPermission(permissionsArr, ['superadmin']) ? { name: t('FUNCTIONS'), url: '/manage-configurations/functions' } : {},
            checkPermission(permissionsArr, ['superadmin']) ? { name: t('ADD_FUNCTION'), url: '/add-function' } : {},

            checkPermission(permissionsArr, ['superadmin']) ? { name: t('GROUP_FUNCTIONS'), url: '/manage-configurations/group_functions' } : {},
            checkPermission(permissionsArr, ['superadmin']) ? { name: t('ADD_GROUP_FUNCTION'), url: '/add-group-function' } : {},

            checkPermission(permissionsArr, ['superadmin']) ? { name: t('SECTORS'), url: '/manage-configurations/sectors' } : {},
            checkPermission(permissionsArr, ['superadmin']) ? { name: t('ADD_SECTOR'), url: '/add-sector' } : {},

            checkPermission(permissionsArr, ['superadmin']) ? { name: t('MIN_SALARY'), url: '/manage-configurations/min_salary' } : {},
            checkPermission(permissionsArr, ['superadmin']) ? { name: t('ADD_FLEX_SALARY'), url: '/add-flex-salary' } : {},
            checkPermission(permissionsArr, ['superadmin']) ? { name: t('INCREASE_MINIMUM_SALARIES'), url: '/manage-configurations/min_salary' } : {},

            checkPermission(permissionsArr, ['superadmin']) ? { name: t('REASONS'), url: '/manage-configurations/reasons' } : {},
            checkPermission(permissionsArr, ['superadmin']) ? { name: t('ADD_REASONS'), url: '/add-reasons' } : {},

            checkPermission(permissionsArr, ['superadmin']) ? { name: t('SOCIAL_SECRETARY'), url: '/manage-configurations/social_secretary' } : {},
            checkPermission(permissionsArr, ['superadmin']) ? { name: t('ADD_SOCIAL_SECRETARY'), url: '/add-social-secretary' } : {},

            checkPermission(permissionsArr, ['superadmin']) ? { name: t('INTERIM_AGENCIES'), url: '/manage-configurations/interim-agencies' } : {},
            checkPermission(permissionsArr, ['superadmin']) ? { name: t('ADD_INTERIM_AGENCIES'), url: '/add-interim-agency' } : {},

            checkPermission(permissionsArr, ['superadmin']) ? { name: t('DEFAULT_PARAMETER'), url: '/manage-configurations/default_param' } : {},
            checkPermission(permissionsArr, ['superadmin']) ? { name: t('PARAMETER'), url: '/manage-configurations/parameters' } : {},

            checkPermission(permissionsArr, ['superadmin']) ? { name: t('SOCIAL_SECRETARY_ENGINES'), url: '/manage-configurations/social_secretary_engines' } : {},

            checkPermission(permissionsArr, ['superadmin']) ? { name: t('ROLES'), url: '/manage-configurations/roles' } : {},
            checkPermission(permissionsArr, ['superadmin']) ? { name: t('ADD_ROLE'), url: '/add-role' } : {},

            checkPermission(permissionsArr, ['superadmin']) ? { name: t('HOLIDAY_CODES'), url: '/manage-holiday-configurations/holiday_code' } : {},
            checkPermission(permissionsArr, ['superadmin']) ? { name: t('ADD_HOLIDAY_CODE'), url: '/add-holiday-code' } : {},

            checkPermission(permissionsArr, ['superadmin']) ? { name: t('HOLIDAY_CODE_CONFIGURATION'), url: '/manage-holiday-configurations/holiday_code_configuration' } : {},

            checkPermission(permissionsArr, ['superadmin']) ? { name: t('MANAGE_PUBLIC_HOLIDAY'), url: '/manage-holiday-configurations/public_holiday_configuration' } : {},
            checkPermission(permissionsArr, ['superadmin']) ? { name: t('ADD_PUBLIC_HOLIDAY'), url: '/add-public-holiday' } : {},

            checkPermission(permissionsArr, ['superadmin']) ? { name: t('EMAIL_TEMPLATES'), url: '/manage-communication-configurations/email' } : {},
            checkPermission(permissionsArr, ['superadmin', 'moderator']) ? { name: t('TRANSLATIONS'), url: '/manage-communication-configurations/translation' } : {},

            checkPermission(permissionsArr, ['superadmin']) ? { name: t('CONTRACT_TEMPLATES'), url: 'manage-communication-configurations/contracts_template' } : {},
            checkPermission(permissionsArr, ['superadmin']) ? { name: t('ADD_CONTRACT_TEMPLATE'), url: '/add-contracts-template/template' } : {},

            checkPermission(permissionsArr, ['superadmin', 'moderator']) ? { name: t('FAQ_OVERVIEW'), url: '/faq-overview' } : {},

            checkPermission(permissionsArr, ['superadmin']) ? { name: t('TAXES'), url: '/manage-social-secretary-and-reporting-configurations/taxes' } : {},
            checkPermission(permissionsArr, ['superadmin']) ? { name: t('ADD_TAXES'), url: '/add-taxes' } : {},

            checkPermission(permissionsArr, ['superadmin']) ? { name: t('SALARY_COEFFICIENT'), url: '/manage-social-secretary-and-reporting-configurations/salary_coefficient' } : {},
            checkPermission(permissionsArr, ['superadmin']) ? { name: t('ADD_SALARY_COEFFICIENT'), url: '/add-salary-coefficient' } : {},

            checkPermission(permissionsArr, ['superadmin']) ? { name: t('WORKCODES'), url: '/manage-social-secretary-and-reporting-configurations/workcodes' } : {},
            checkPermission(permissionsArr, ['superadmin']) ? { name: t('ADD_WORKCODE'), url: '/add-workcode' } : {},

            //Settings
            checkPermission(permissionsArr, ['superadmin']) ? { name: t('GENDER'), url: '/settings' } : {},
            checkPermission(permissionsArr, ['superadmin']) ? { name: t('MARITAL_STATUS'), url: '/settings' } : {},
            checkPermission(permissionsArr, ['superadmin']) ? { name: t('MEAL_VOUCHERS'), url: '/settings' } : {},
            checkPermission(permissionsArr, ['superadmin']) ? { name: t('COMMUTE_TYPES'), url: '/settings' } : {},
            checkPermission(permissionsArr, ['superadmin']) ? { name: t('DIMONA_ERROR_CODES'), url: '/settings' } : {},

            //Monthly overview
            checkPermission(permissionsArr, ['superadmin', 'monthly_overview']) ? { name: t('PROCESS_PLANNINGS'), url: '/monthly-overview' } : {},
            checkPermission(permissionsArr, ['superadmin', 'monthly_overview']) ? { name: t('EXPORT_SOCIAL_SECRETARY'), url: '/monthly-overview' } : {},
            checkPermission(permissionsArr, ['superadmin', 'moderator']) ? { name: t('ADD_NOTIFICATION'), url: 'notifications-form' } : {},

            { name: t('MY_ACCOUNT'), url: '/my-account' },
            { name: t('CHANGE_PASSWORD'), url: '/my-account#changePassword' },
            { name: t('ENABLE_NOTIFICATION'), url: '/my-account#enableNotification' },
        ]
        setSuggestions(suggestions_arr);
    }, [refresh])

    const onSuggestionSelected = (event, { suggestion }) => {
        setSearchBar(!searchBar);
        setValue('');
        navigate(suggestion.url);
    }

    function sortByName(arr) {
        return arr.sort((a, b) => a.name.localeCompare(b.name));
      }

    const handleSearch = (val) => {
        const searchTerm = val
        const results = suggestions.filter(item =>
            // console.log(item)
            item.name?.toLowerCase().includes(searchTerm?.toLowerCase())
        );
        setSuggestions(sortByName(results));
    };


    return (
        <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={({ value }) => {
                // Fetch suggestions based on the input value
                // and update the suggestions state
                handleSearch(value);
                // setSuggestions(data);
            }}
            onSuggestionsClearRequested={() => {
                // Clear suggestions when needed
                // setSuggestions([]);
                setRefresh(!refresh)
            }}
            getSuggestionValue={(suggestion) => suggestion.name}
            renderSuggestion={(suggestion) => <div>{suggestion.name}</div>}
            inputProps={{
                placeholder: 'Type something...',
                value,
                onChange: (_, { newValue }) => setValue(newValue),
                ref: inputRef,
            }}
            onSuggestionSelected={onSuggestionSelected}
            highlightFirstSuggestion={true}
        />
    )
}
