import React from "react";
const ExportIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        id="Export"
        className="export-icon"
      >
        <path
          d="M1.021 2v11.998h10v-4h1V12l.801-.6 2.533-1.898-3.334-2.508v2.004h-1v-4l.002-.002-.002-.002-2.994-2.99V2zm1 1h5.006l-.004 2.994h2.998v3.004h-4v1h4v3h-8zm6.004.416 1.582 1.578H8.023z"
          fill="#073763"
        />
      </svg>
    );
  };
  
  export default ExportIcon;