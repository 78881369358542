import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { APICALL as AXIOS } from "../../services/AxiosServices"
import { t } from "../../translations/Translation";
import ErrorPopup from "../../utilities/popup/ErrorPopup";
import FormsNew from "./FormsNew";
import { GetWorkedEmployeesListToExportApiUrl, ExportEmployeeRecordApiUrl, ExportAttendanceApiUrl } from "../../routes/ApiEndPoints";
import { getFormattedDropdownOptions } from "../../utilities/CommonFunctions";

export default function ExportSocialSecretaryPopup(props) {

    const [errors, setErrors] = useState([])
    const [formData, setFormData] = useState({
        "start_date": "",
        "end_date": "",
        "employee_types": [],
        'employee_ids': []
    })
    const [employeeList, setEmployeeList] = useState([])
    const [selectedEmpTypes, setSelectedEmpTypes] = useState([])
    const [selectedEmployees, setSelectedEmployees] = useState([])
    const [displayDropdown, setDisplayDropdown] = useState(false)
    const [success, setSuccess] = useState(true)
    const [showWarning, setShowWarning] = useState(false)
    const [warningMessage, setWarningMessage] = useState([])


    const setValues = (index, name, value, field) => {

        let newdata = { ...formData }
        if (field !== 'dropdown') {
            newdata[name] = value
            setFormData(newdata)
        } else {
            if (name === 'employee_types') {
                setSelectedEmpTypes(value);
                let arr = []
                value.map((item) => {
                    arr.push(item.value)
                })
                newdata['employee_types'] = arr
            } else {
                setSelectedEmployees(value)
                let arr = []
                value.map((item) => {
                    arr.push(item.value)
                    return null
                })
                newdata[name] = arr
            }

        }
        setFormData(newdata)
    }

    useEffect(() => {
        if (formData.start_date && formData.end_date) {
            AXIOS.service(GetWorkedEmployeesListToExportApiUrl, 'POST', formData)
                .then((result) => {
                    if (result.success) {
                        setEmployeeList(getFormattedDropdownOptions(result.data, 'value', 'name'))
                        setDisplayDropdown(true)
                    } else {
                        setErrors(result.message)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }


    }, [formData?.start_date, formData?.end_date, formData?.employee_types])

    const onConfirm = () => {
        setSuccess(false)
        AXIOS.service(ExportEmployeeRecordApiUrl, 'POST', formData)
            .then((result) => {
                if (result?.success) {
                    setSuccess(true)
                    const link = document.createElement('a');
                    link.href = result.data.url;
                    link.setAttribute('download', 'ExportedData.zip');
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                    let list = result.data?.warning_list
                    if (list.length > 0) {
                        setShowWarning(true)
                        setWarningMessage(list)
                    } else {
                        props.onHide()
                    }
                } else {
                    setErrors(result.message)
                    setSuccess(true)
                }
            })
            .catch((error) => {
                console.log(error);
                setSuccess(true)
            })
    }

    const onGenerateExcel = () => {
        setSuccess(false)
        AXIOS.service(ExportAttendanceApiUrl, 'POST', formData)
            .then((result) => {
                if (result?.success) {
                    setSuccess(true)
                    const link = document.createElement('a');
                    link.href = result.data;
                    link.setAttribute('download', 'ExportedData.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                    props.onHide()
                } else {
                    setErrors(result.message)
                    setSuccess(true)
                }
            })
            .catch((error) => {
                console.log(error);
                setSuccess(true)
            })
    }



    let fieldsArray = [
        { title: t('START_DATE'), name: 'start_date', required: true, type: 'date', style: "col-md-4 float-left" },
        { title: t('END_DATE'), name: 'end_date', required: true, type: 'date', style: "col-md-4 float-left" },
        { title: t("EMPLOYEE_TYPE"), name: 'employee_types', required: false, options: props.employeeTypeArr, selectedOptions: selectedEmpTypes, isMulti: true, type: 'dropdown', style: "col-md-4 float-left" },
        (displayDropdown ? { title: t("EMPLOYEES"), name: 'employee_ids', required: false, options: employeeList, selectedOptions: selectedEmployees, isMulti: true, type: 'dropdown', style: "col-md-4 mt-3 float-left" } : '')
    ];


    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="xl"
            className=""
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('EXPORT_SOCIAL_SECRETARY')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="popup-tabs border">
                    {errors !== undefined && errors.length !== 0 && <ErrorPopup
                        title={t("VALIDATION_ERROR") + ("!")}
                        body={(errors)}
                        onHide={() => setErrors([])}
                    ></ErrorPopup>}
                    <FormsNew
                        formTitle={''}
                        formattedData={formData}
                        data={fieldsArray}
                        SetValues={setValues}
                    ></FormsNew>
                    {showWarning &&
                        < div className="p-4">
                            <h4 className="text-danger bold pl-2"><span className="highlight ro">{t("LIST_OF_NOT_EXPORTED_EMPOYEES")}:</span></h4>
                            <div className={warningMessage.length > 150 ? "employee-detail-height pl-5 d-flex mb-3 bg-grey" : "pl-5 d-flex mb-3 bg-grey "}>
                                <div className="col-4">
                                    <ul className="full-width-list">
                                        {warningMessage.slice(0, Math.ceil(warningMessage.length / 3)).map((val) => {
                                            return (<li>{val}</li>)
                                        })}
                                    </ul>
                                </div>
                                <div className="col-4">
                                    <ul className="full-width-list">
                                        {warningMessage.slice(Math.ceil(warningMessage.length / 3), Math.ceil(warningMessage.length / 3) * 2).map((val) => {
                                            return (<li >{val}</li>)
                                        })}
                                    </ul>
                                </div>
                                <div className="col-4">
                                    <ul className="full-width-list">
                                        {warningMessage.slice(Math.ceil(warningMessage.length / 3) * 2).map((val) => {
                                            return (<li>{val}</li>)
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className='button-style float-left' onClick={() => onGenerateExcel()} disabled={!success}>
                    {t("GENERATE_EXCEL")}
                </Button>
                <Button className='button-style float-left' onClick={() => onConfirm()} disabled={!success}>
                    {t("GENERATE_REPORT")}
                </Button>
                <Button className='button-style' onClick={props.onHide} >
                    {props.buttonName ? (props.buttonName) : t("CANCEL")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
