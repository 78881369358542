import { GetRowValues, GetTotalValues } from "../../../../utilities/CommonFunctions";

export const FormulaConfiguration = (value, index, max_hours, row_number, sheetType) => ({

    "fulltime": {
        "date": value,
        "contract_hours": value,
        "total_worked_hours": value,
        "worked_hours": `=ROUND((C${index + 1}-G${index + 1}), 2)`, //=0-F13
        "planned_hours": value,
        "split": sheetType ? `=ROUND(IF(B${index + 1}-L${index + 1}<0,0,B${index + 1}-L${index + 1}), 2)` : value, //=B13-K13
        "break": value,
        "night_hours": value,
        "overtime_100": `=ROUND(IF(D${index + 1}-K${index + 1}-F${index + 1}<0,0,D${index + 1}-K${index + 1}-F${index + 1}), 2)`, //=C15-J15-E15
        "overtime_type": value,
        "overtime_2": `=ROUND(IF(D${index + 1}-${max_hours}<0,0,D${index + 1}-${max_hours}), 2)`, //=IF(C15-R$9<0,0,C15-R$9)
        "leave_hours": `=ROUND(IF(B${index + 1}-D${index + 1}<0,0,B${index + 1}-D${index + 1}), 2)`, //=IF(B13-C13<0,0,B13-C13)
        "leave_code": value,
        "bruto_netto": (index == 0) ? `=I${index + 1}` : `=ROUND((N${index}+I${index + 1}), 2)`, //=M13+H14
        "cost": value, //=(E15*J$7*J$8)+(H15*J$7*1)+(J15*J$7*1.5*J$8)+(K15*J$7*J$8)+(G15*J$9*J$8)
        // Add more column configurations as needed
    },
    // @todo 
    "partime": {
        "date": value,
        "contract_hours": value,
        "total_worked_hours": value,
        "worked_hours": `=ROUND((C${index + 1}-G${index + 1}), 2)`, //=0-F13
        "planned_hours": value,
        "split": sheetType ? `=ROUND(IF(B${index + 1}-L${index + 1}<0,0,B${index + 1}-L${index + 1}), 2)` : value, //=B13-K13
        "break": value,
        "night_hours": value,
        "overtime_100": `=ROUND(IF(D${index + 1}-K${index + 1}-F${index + 1}<0,0,D${index + 1}-K${index + 1}-F${index + 1}), 2)`, //=C15-J15-E15
        "overtime_type": value,
        "overtime_2": `=ROUND(IF(D${index + 1}-${max_hours}<0,0,D${index + 1}-${max_hours}), 2)`, //=IF(C15-R$9<0,0,C15-R$9)
        "leave_hours": `=ROUND(IF(B${index + 1}-D${index + 1}<0,0,B${index + 1}-D${index + 1}), 2)`, //=IF(B13-C13<0,0,B13-C13)
        "leave_code": value,
        "cost": value, //=(E15*J$7*J$8)+(H15*J$7*1)+(J15*J$7*1.5*J$8)+(K15*J$7*J$8)+(G15*J$9*J$8)
        "overtime": (index == 0) ? `=I${index + 1}` : `=ROUND((N${index}+I${index + 1}), 2)`, //=M13+H14
        // Add more column configurations as needed
    },
    // @todo 
    "grote_flex": {
        "date": value,
        "contract_hours": value,
        "total_worked_hours": value,
        "worked_hours": `=ROUND((C${index + 1}-G${index + 1}), 2)`, //=0-F13
        "planned_hours": value,
        "split": sheetType ? `=ROUND(IF(B${index + 1}-L${index + 1}<0,0,B${index + 1}-L${index + 1}), 2)` : value, //=B13-K13
        "break": value,
        "night_hours": value,
        "overtime_100": `=ROUND(IF(D${index + 1}-K${index + 1}-F${index + 1}<0,0,D${index + 1}-K${index + 1}-F${index + 1}), 2)`, //=C15-J15-E15
        "overtime_type": value,
        "overtime_2": `=ROUND(IF(D${index + 1}-${max_hours}<0,0,D${index + 1}-${max_hours}), 2)`, //=IF(C15-R$9<0,0,C15-R$9)
        "leave_hours": `=ROUND(IF(B${index + 1}-D${index + 1}<0,0,B${index + 1}-D${index + 1}), 2)`, //=IF(B13-C13<0,0,B13-C13)
        "leave_code": value,
        "cost": value, //=(E15*J$7*J$8)+(H15*J$7*1)+(J15*J$7*1.5*J$8)+(K15*J$7*J$8)+(G15*J$9*J$8)
        "bruto_netto": `=ROUND(IF(J${index + 1}="bruto_netto", IF(${index}=0, I${index + 1}, O${index}+I${index + 1}), 0), 2)`, //`=I${index+1}` === 'bruto_netto' ? ((index == 0) ? `=H${index + 1}` : `=ROUND((N${index}+H${index + 1}), 2)`): 0, //=N13+H14
        "plus_hours": `=ROUND(IF(J${index + 1}="plusuren", IF(${index}=0, I${index + 1}, N${index}+I${index + 1}), 0), 2)`, //=I${index+1}` === "plusuren" ? ((index == 0) ? `=H${index + 1}` : `=ROUND((M${index}+H${index + 1}), 2)`): 0, //=M13+H14
        // Add more column configurations as needed
    },

    // Total week data
    "week_fulltime": {
        "week": value,
        "contract_hours": GetRowValues(index, `B`, row_number), //`=ROUND((B1+B2+B3+B4), 2)`,
        "total_worked_hours": value,
        "worked_hours": GetRowValues(index, `D`, row_number), //=0-F13
        "planned_hours": value,
        "split": GetRowValues(index, `F`, row_number), //=B13-K13
        "break": GetRowValues(index, `G`, row_number),
        "night_hours": GetRowValues(index, `H`, row_number),
        "overtime_100": GetRowValues(index, `I`, row_number), //=C15-J15-E15
        "overtime_type": value,
        "overtime_2": GetRowValues(index, `K`, row_number), //=IF(C15-R$9<0,0,C15-R$9)
        "leave_hours": GetRowValues(index, `L`, row_number), //=IF(B13-C13<0,0,B13-C13)
        "leave_code": value,
        "plus_hours": value, //=M13+H14
        "bruto_netto": value, //=M13+H14
        "cost": GetRowValues(index, sheetType === 'grote_flex' ? `P` : `O`, row_number), //=(E15*J$7*J$8)+(H15*J$7*1)+(J15*J$7*1.5*J$8)+(K15*J$7*J$8)+(G15*J$9*J$8)
        // Add more column configurations as needed
    },

    // Total data
    "total_fulltime": {
        "week": value,
        "contract_hours": GetTotalValues(index, `B`, row_number), //`=ROUND((B1+B2+B3+B4), 2)`,
        "total_worked_hours": value,
        "worked_hours": GetTotalValues(index, `D`, row_number), //=0-F13
        "planned_hours": value,
        "split": GetTotalValues(index, `F`, row_number), //=B13-K13
        "break": GetTotalValues(index, `G`, row_number),
        "night_hours": GetTotalValues(index, `H`, row_number),
        "overtime_100": GetTotalValues(index, `I`, row_number), //=C15-J15-E15
        "overtime_type": value,
        "overtime_2": GetTotalValues(index, `K`, row_number), //=IF(C15-R$9<0,0,C15-R$9)
        "leave_hours": GetTotalValues(index, `L`, row_number), //=IF(B13-C13<0,0,B13-C13)
        "leave_code": value,
        "bruto_netto": value, //=M13+H14
        "plus_hours": value, //=M13+H14
        "cost": GetTotalValues(index, sheetType === 'grote_flex' ? `P` : `O`, row_number), //=(E15*J$7*J$8)+(H15*J$7*1)+(J15*J$7*1.5*J$8)+(K15*J$7*J$8)+(G15*J$9*J$8)
        // Add more column configurations as needed
    },

});
