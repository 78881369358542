import React, { useEffect, useState, useCallback } from "react";
import { t } from "../../translations/Translation";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import CustomPopup from "../../utilities/popup/CustomPopup";
import FormsNew from "./FormsNew";
import { debounce, getFormattedDropdownOptions } from "../../utilities/CommonFunctions";
import { EmployeeContractApiUrl, FetchMinSalaryApisUrl, LinkFunctionToContractApiUrl } from "../../routes/ApiEndPoints";
import ErrorPopup from "../../utilities/popup/ErrorPopup";
import { toast } from "react-toastify";

export default function LinkFunctionToContract({ contractId, setShowLinkFunctionPopup, setDataRefresh, dataRefresh, eid, debouncedMinSal }) {
    const [functionsList, setFunctionsList] = useState([])
    const [selectedFunction, setSelectedFunction] = useState([])
    const [errors, setErrors] = useState([])
    const [useMinSal, setUseMinSal] = useState(false)

    const [formData, setFormData] = useState({
        "employee_contract_id": contractId,
        "function_id": "",
        "start_date": "",
        "salary": "",
        "experience": ""
    })
    const checkboxList = [{ key: 1, value: "", name: t("USE_MINIMUM") }]

    useEffect(() => {
        AXIOS.service(EmployeeContractApiUrl + '/create', 'GET')
            .then((result) => {
                if (result?.success) {
                    let GroupedOptions = [
                        {
                            label: t('COMPANY_FUNCTIONS'),
                            options: getFormattedDropdownOptions(result.data?.functions?.company_function_titles, "id", "name")
                        },
                        {
                            label: t('OTHER_FUNCTIONS'),
                            options: getFormattedDropdownOptions(result?.data?.functions?.sector_function_titles, "id", "name")
                        },
                    ]
                    setFunctionsList(GroupedOptions)
                } else {
                    // setErrors(result.message)
                }
            })
    }, [])

    const fetchMinSalary = (fid, experience) => {
        let data = {
            "employee_contract_id": contractId,
            "function_title_id": fid,
            "experience_in_months": experience,
            "employee_profile_id": eid
        }
        if (experience) {
            AXIOS.service(FetchMinSalaryApisUrl, "POST", data)
                .then((result) => {
                    if (result?.success) {
                        setFormData((prev) => {
                            let newData = { ...prev }
                            newData["min_salary"] = result.data.minimumSalary
                            if (useMinSal) {
                                newData["salary"] = result.data.minimumSalary
                            }
                            return newData
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        } else {
            setFormData((prev) => {
                let newData = { ...prev }
                newData["min_salary"] = experience
                return newData
            })
        }

    }



    const setValues = (index, name, value, field) => {
        let newData = { ...formData }
        if (field !== 'dropdown') {
            if (name === 'experience') {
                value ? debouncedMinSal(newData.function_id, value, contractId, eid, setFormData, useMinSal) : newData["min_salary"] = ""
            }
            if (name === 'salary') {
                value === newData.min_salary ? setUseMinSal(true) : setUseMinSal(false)
            }
            newData[name] = value
        } else {
            if (newData.experience) {
                fetchMinSalary(value.value, newData.experience)
            } else {

                newData["min_salary"] = ""
            }
            setSelectedFunction(value)
            console.log(value?.value);
            
            newData[name] = value?.value ? value.value : ""
        }
        setFormData(newData)
    }

    const onSave = () => {

        AXIOS.service(LinkFunctionToContractApiUrl, "POST", formData)
            .then((result) => {
                if (result.success) {
                    setDataRefresh(!dataRefresh)
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setShowLinkFunctionPopup(false)
                } else {
                    setErrors(result.message)
                }
            })
            .catch((errors) => {
                console.log(errors);

            })
    }

    const changeCheckbox = () => {
        setUseMinSal(!useMinSal)
        if (!useMinSal) {
            setFormData((prev) => {
                let newData = { ...prev }
                newData["salary"] = newData.min_salary
                return newData
            })
        } else {
            setFormData((prev) => {
                let newData = { ...prev }
                newData["salary"] = ""
                return newData
            })
        }
    }



    let fieldsArray = [
        { title: t("FUNCTION_NAME"), name: "function_id", type: "dropdown", options: functionsList, selectedOptions: selectedFunction, required: true, style: "col-md-6 float-left mt-2" },
        { title: t("EXPERIENCE_IN_MONTHS"), name: "experience", type: "text", style: "col-md-6 float-left mt-2" },
        { title: t("MINIMUM_SALARY"), name: "min_salary", type: "text", style: "col-md-6 float-left mt-2 ", disabled: true },
        { title: "", name: "", checkboxList: checkboxList, changeCheckbox: changeCheckbox, checked: useMinSal, type: "checkbox", style: "mt-4 col-md-6 float-left" },
        { title: t("SALARY"), name: "salary", type: "text", style: "col-md-6 float-left mt-2 " },
        { title: t("APPLY_CHANGES_FROM"), name: "start_date", type: "date", disabled: false, style: "col-md-6 float-left  mt-2" },
    ]

    return (
        <CustomPopup
            title={<span id="text-indii-blue">{t("LINK_FUNCTION")}</span>}
            size="xl"
            show={true}
            onHide={() => setShowLinkFunctionPopup(false)}
            header
            footer
            onConfirm={() => onSave()}
            close
        >
            <>
                {errors !== undefined && errors.length !== 0 && <ErrorPopup
                    title={t("VALIDATION_ERROR") + ("!")}
                    body={(errors)}
                    onHide={() => setErrors([])}
                ></ErrorPopup>}
                <FormsNew
                    view='filters'
                    formattedData={formData}
                    data={fieldsArray}
                    SetValues={setValues}
                />
            </>

        </CustomPopup >
    )
}