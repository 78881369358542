import React, { useState, useEffect, useRef } from "react";
import Legend from "../atoms/Legend";
import { t } from "../../translations/Translation";
import { GetMessageBoardNotificationsApiUrl, DeleteNotificationApiUrl } from '../../routes/ApiEndPoints'
import { APICALL as AXIOS } from "../../services/AxiosServices";
import DeleteIcon from "../../static/icons/Close.png"
import { toast } from "react-toastify";


export default function Messageboard({ Messages }) {

    const [messageBoardData, setMessageBoardData] = useState([]);
    const [filter, setFilter] = useState([])
    const [nexPageUrl, setNextPageUrl] = useState('')

    const messageBoardRef = useRef(null);


    const handleFilter = (type) => {
        let url = '';

        if (type === 'employee') {

        } else if (type === 'dimona') {

        } else if (type === 'indii') {

        } else {

        }

        AXIOS.service(url, 'GET')
            .then((result) => {

                if (result.success) {
                    setMessageBoardData(result.data?.notifications)
                    setNextPageUrl(result.data?.links?.next)
                } else {

                }

            })
    }

    const onFilterSelect = (type) => {
        // let arr = [...filter];
        // const index = arr.indexOf(type);

        // if (index !== -1) {
        //     arr.splice(index, 1);
        // } else {
        //     arr.push(type);
        // }

        // setFilter(arr);
    };


    const loadMoreData = (url) => {
        AXIOS.service(url, 'GET')
            .then((result) => {
                if (result.success) {
                    setMessageBoardData(prev => { return prev.concat(result.data?.notifications) })
                    setNextPageUrl(result.data?.links?.next)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleScroll = () => {
        const board = messageBoardRef.current
        if (board.scrollTop + board.clientHeight >= board.scrollHeight) {
            loadMoreData(nexPageUrl)
        }
    }

    useEffect(() => {
        const board = messageBoardRef.current
        board.addEventListener('scroll', handleScroll)
        return () => {
            board.removeEventListener('scroll', handleScroll)
        }
    }, [])


    useEffect(() => {
        AXIOS.service(GetMessageBoardNotificationsApiUrl, 'GET')
            .then((result) => {
                if (result.success) {
                    setMessageBoardData(result.data.notifications)
                    setNextPageUrl(result.data?.links?.next)
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }, [])

    const handleCancel = (index, id) => {

        AXIOS.service(`${DeleteNotificationApiUrl}/${id}`, 'DELETE')
            .then((result) => {
                if (result.success) {
                    let newData = [...messageBoardData]
                    newData.splice(index, 1)
                    setMessageBoardData(newData)
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    toast.error(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            }).catch((error) => {
                console.log(error);
            });

    }

    const style1 = 'indii-message text-blue'
    const style2 = 'dimona-message text-red'
    const style3 = 'employee-message text-green'

    return (
        <div className="col-md-3 p-0 bg-white border-left font-12 dashboard_height " ref={messageBoardRef}>
            <h4 className="m-3 mt-4">{t("MESSAGE_BOARD")}</h4>
            <div className="row m-0 d-flex ">
                <Legend title={t('EMPLOYEE_MESSAGE_TITLE')} styleClass1={"col-xl-8 col-12" + (filter.includes('employee') ? ' shadow border py-2' : '')} onClickFunction={() => onFilterSelect('employee')} styleClass2={"box background-green"}></Legend>
                <Legend title={t('DIMONA_MESSAGE_TITLE')} styleClass1={"col-xl-8 col-12" + (filter.includes('dimona') ? ' shadow border py-2' : '')} onClickFunction={() => onFilterSelect('dimona')} styleClass2={"box background-red"}></Legend>
                <Legend title={t('INDII_MESSAGE_TITLE')} styleClass1={"col-xl-8  col-12" + (filter.includes('indii') ? ' shadow border py-2' : '')} onClickFunction={() => onFilterSelect('indii')} styleClass2={"box background-blue"}></Legend>
                {/* <Legend title={t('ALL')} styleClass1={"col-xl-7  col-12" + (filter === '' ? ' shadow border py-2' : '')} onClick={() => setFilter('')} styleClass2={"box border bg-warning"}></Legend> */}
            </div>
            <ul className="mt-3 p-0">
                {
                    messageBoardData.map((val, index) => {
                        return (
                            <li key={index} className={"list-group p-2 px-3 m-3 my-2 position-relative " + (val.type === undefined ? style1 : (val.type === '' ? style2 : style3))}><p className="mb-0">{val.body}</p> <img
                                className="message-board-icon position-absolute top_0px right_0px m-1 "
                                src={DeleteIcon}
                                alt="Cancel"
                                onClick={() => handleCancel(index, val.id)}
                            /></li>
                        )
                    })
                }
            </ul>
        </div>
    )
}
