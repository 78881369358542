import React, { useState, useEffect } from "react";
import FormsNew from "../molecules/FormsNew";
import ErrorPopup from "../../utilities/popup/ErrorPopup";
import { toast } from 'react-toastify';
import { useNavigate, useParams } from "react-router-dom";
import { APICALL as AXIOS } from "../../services/AxiosServices"
import { WorkCodesApiUrl } from "../../routes/ApiEndPoints"
import { t } from "../../translations/Translation";

export default function AddWorkCode() {

    const [formData, setFormData] = useState({
        "name": "",
        "percentage": "",
        "internal_code": "",
        "description": "",
        "status": true,
    })

    const [active, setActive] = useState(true);
    const [inactive, setInactive] = useState(false);

    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();
    const params = useParams();
    const [Success, setSuccess] = useState(true);

    // Checkbox status data
    const changeCheckbox = (type) => {
        if (type === 'active') {
            setActive(true);
            setInactive(false);
        } else {
            setActive(false);
            setInactive(true);
        }
    }
    const checkboxList = [
        {
            name: t("ACTIVE"),
            key: 'active',
            checked: active,
        },
        {
            name: t("INACTIVE"),
            key: 'inactive',
            checked: inactive,
        }
    ]


    useEffect(() => {
        if (params.id !== undefined) {
            AXIOS.service(WorkCodesApiUrl + '/' + params.id, 'GET')
                .then((result) => {
                    if (result?.success) {
                        let data = result.data
                        setFormData(data)
                    } else {
                        setErrors(result.message)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [])


    const setValues = (index, name, value, field) => {
        const newData = { ...formData };
        if (field !== 'dropdown') {
            newData[name] = value
        }
        setFormData(newData);
    }


    const onSave = () => {

        let url = WorkCodesApiUrl
        let method = 'PUT'

        // Update url and method
        if (params.id !== undefined) {
            url = WorkCodesApiUrl + '/' + params.id
            // formData['id'] = params.id
        }
        // APICall for create and update workcode configuration
        AXIOS.service(url, method, formData)
            .then((result) => {
                if (result?.success) {
                    navigate('/manage-social-secretary-and-reporting-configurations/workcodes');
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setSuccess(true)
                } else {
                    setErrors(result.message)
                    setSuccess(true)
                }
            })
            .catch((error) => {
                console.log(error);
                setSuccess(true)
            })
    }



    const Workcodefields = [
        { title: t("NAME_TEXT"), name: "name", required: true, type: "text", style: "col-md-6 mt-4 float-left" },
        { title: t("PERCENTAGE"), name: "percentage", type: "text", required: true, style: "col-md-6 float-left mt-4" },
        { title: t("INTERNAL_CODE"), name: "internal_code", type: "text", required: true, style: "col-md-6 float-left mt-4" },
        { title: t("DESCRIPTION"), name: 'description', required: false, type: 'text-area', style: "col-md-12 mt-4 mb-5 float-left" },
        { title: t("STATUS_TEXT"), required: true, type: 'checkbox', checkboxList: checkboxList, changeCheckbox: changeCheckbox, style: "col-md-12 mt-4 mb-2 float-left" },
    ];

    return (
        <div className="right-container add_taxes">
            {errors !== undefined && errors.length !== 0 && <ErrorPopup
                title={t("VALIDATION_ERROR") + ("!")}
                body={(errors)}
                onHide={() => setErrors([])}
            ></ErrorPopup>}
            <FormsNew
                view="public_holiday"
                formTitle={t("ADD_WORKCODE")}
                redirectURL={'/manage-social-secretary-and-reporting-configurations/workcodes'}
                formattedData={formData}
                data={Workcodefields}
                SetValues={setValues}
                OnSave={onSave}
                setSuccess={setSuccess}
                Success={Success}
            ></FormsNew>
        </div>
    )
}