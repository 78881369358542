import React, { useEffect, useState, useImperativeHandle, forwardRef, useRef } from "react";
import FormsNew from "./FormsNew";
import EditIcon from "../../static/icons/edit-dark.svg"
import CustomButton from "../atoms/CustomButton";
import { getFormattedDropdownOptions, getFormattedRadioOptions } from "../../utilities/CommonFunctions";
import RadioInput from "../atoms/formFields/RadioInput";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import { EmployeeContractApiUrl, FetchMinSalaryApisUrl, UpdateEmployeeContractFunctionApiUrl, DeactivateEmployeeFunctionApiUrl, DeleteEmployeeFunctionApiUrl } from "../../routes/ApiEndPoints";
import { toast } from 'react-toastify';
import { t } from "../../translations/Translation";
import ErrorPopup from "../../utilities/popup/ErrorPopup";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ModalPopup from "../../utilities/popup/Popup";
import Table from "../atoms/Table";
import CustomPopup from "../../utilities/popup/CustomPopup";
// import getFormattedDropdownOptions from

export default function UpdateEmployeeContractDetailsForm({ data, eid, edit, setEditStatus, setToggleOpen, toggleOpen, setDataRefresh, dataRefresh, pastContracts }) {
    //creating deep copy
    const copy = JSON.parse(JSON.stringify(data));
    const response = JSON.parse(JSON.stringify(data));
    let contractId = data.id

    const [formData, setFormData] = useState(response)
    const [employeeTypeList, setEmployeeTypeList] = useState([])
    const [employeeType, setEmployeeType] = useState("")
    const [subTypeList, setSubTypeList] = useState([])
    const [subType, setSubType] = useState("")
    const [functionList, setFunctionList] = useState([])
    const [functionName, setFunctionName] = useState("")
    const [showData, setShowData] = useState(true)
    const [editFunction, setEditFunction] = useState(false)
    const [cardNumber, setCardNumber] = useState("")
    const [isLongTermContract, setisLongTermContract] = useState(data.long_term)
    const [selectedScheduleType, setSelectedScheduleType] = useState(response.schedule_type)
    const [selectedEmploymentType, setSelectedEmploymentType] = useState(response.employment_type)
    const [functionIndex, setFuncitonIndex] = useState("")
    const [refresh, setRefresh] = useState(false)

    const [scheduleTypeArray, setScheduleTypeArray] = useState([])
    const [employementTypeArray, setEmployementTypeArray] = useState([])
    const [functionData, setFunctionData] = useState([])
    const [functionListArray, setFunctionListArray] = useState([])
    const [longtermEmployeeTypeListArray, setLongtermEmployeeTypeListArray] = useState([])
    const [dayContractEmployeeTypeListArray, setDayContractEmployeeTypeListArray] = useState([])
    const [subTypeListArray, setSubTypeListArray] = useState([])
    const [displaySalary, setDisplaySalary] = useState(data?.employee_function_details)
    const [displayMinumumSalary, setDisplayMinumumSalary] = useState([])
    const [iterimAgenciesList, setInterimAgenciesList] = useState([])
    const [selectedInterimAgency, setSelectedInterimAgency] = useState(response?.interim_agency)//need to prefill selected_interim_agency if contract is interim
    const [isInterimType, setIsInterimType] = useState(formData?.interim_contract ? true : false)
    const [errors, setErrors] = useState([]);
    // const [employeeContractOptions, setEmployeeContractOptions] = useState([]);
    const [isEndDateChanged, setIsEndDateChanged] = useState(false)
    const [wantDimonaUpdate, setWantDimonaupdate] = useState(true)
    const [isDimonaExists, setIsDimonaExists] = useState(data?.dimona_exists)

    const [updateFunctionData, setUpdateFunctionData] = useState('')
    const [updateType, setUpdateType] = useState(false)

    const [functionFormData, setFunctionFormData] = useState({
        "employee_function_id": "",
        "update_from_date": "",
        "salary": "",
        "experience": ""
    })
    const [deleteUrl, setDeleteUrl] = useState("")


    const functionPopupRef = useRef(null);

    useEffect(() => {
        AXIOS.service(EmployeeContractApiUrl + '/create', 'GET')
            .then((result) => {
                if (result?.success) {
                    // setEmployeeContractOptions(result.data)
                    setScheduleTypeArray(getFormattedRadioOptions(result.data?.schedule_types, 'key', 'value'));
                    setEmployementTypeArray(getFormattedRadioOptions(result.data?.employment_types, 'key', 'value'));
                    let arr = data?.employee_function_details.map((val) => { return { ...val, id: val.function_details_id } })
                    // setFunctionData(data?.employee_function_details)
                    setFunctionData(arr)
                    let GroupedOptions = [
                        {
                            label: t('COMPANY_FUNCTIONS'),
                            options: getFormattedDropdownOptions(result.data?.functions?.company_function_titles, "id", "name")
                        },
                        {
                            label: t('OTHER_FUNCTIONS'),
                            options: getFormattedDropdownOptions(result?.data?.functions?.sector_function_titles, "id", "name")
                        },
                    ]
                    setFunctionListArray(GroupedOptions)
                    setLongtermEmployeeTypeListArray(getFormattedDropdownOptions(result.data?.employee_contract_options?.employee_types[1], "key", "name"))
                    setDayContractEmployeeTypeListArray(getFormattedDropdownOptions(result.data?.employee_contract_options?.employee_types[2], "key", "name"))
                    setSubTypeListArray(getFormattedDropdownOptions(result.data?.sub_types, "key", "value"))
                    setInterimAgenciesList(result.data?.interim_agencies)
                } else {
                    // setErrors(result.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, [data])

    let commonData = data?.long_term === true ? [
        { label: t("EMPLOYEE_TYPE"), value: data?.employee_type },
        { label: t("SUB_TYPE"), value: data?.sub_type },
        { label: t("START_DATE"), value: data?.start_date },
        { label: t("END_DATE"), value: data?.end_date },
        { label: t("WEEKLY_CONTRACT_HOURS"), value: data?.weekly_contract_hours },
        { label: t("WORK_DAYS_PER_WEEK"), value: data?.work_days_per_week },
        { label: t("DIMONA_PERIOD_ID"), value: data?.dimona_period_id }
    ] : [
        { label: t("EMPLOYEE_TYPE"), value: data?.employee_type },
        { label: t("START_DATE"), value: data?.start_date },
        { label: t("END_DATE"), value: data?.end_date },
        // { label: t("WEEKLY_CONTRACT_HOURS"), value: data?.weekly_contract_hours },
        // { label: t("WORK_DAYS_PER_WEEK"), value: data?.work_days_per_week },
    ]


    useEffect(() => {
        // setting function list from response
        setFunctionList(functionListArray)
        //setting schedule type and sub type from response
        if (response.schedule_type !== undefined && response.schedule_type !== null && response.employment_type !== undefined && response.employment_type !== null && scheduleTypeArray !== undefined && employementTypeArray !== undefined) {
            response.schedule_type === "part_time" ? setSelectedScheduleType(scheduleTypeArray[0]?.key) : setSelectedScheduleType(scheduleTypeArray[1]?.key)
            response.employment_type === 'fixed' ? setSelectedEmploymentType(employementTypeArray[0]?.key) : setSelectedEmploymentType(employementTypeArray[1]?.key)
        }

        setEditStatus(false)

    }, [toggleOpen, eid, refresh, functionData])

    useEffect(() => {
        // set function when loaded
        functionListArray?.map((item) => {
            item?.options?.map((val) => {
                if (val.value === response.employee_function_details[functionIndex]?.function_id) {
                    setFunctionName(val)
                }
            })
        })

    }, [functionIndex])


    const onRadioSelect = (type, key) => {

        if (type === 'schedule_type') {
            if (key === "part_time") {
                setSelectedScheduleType(key)
            } else {
                setSelectedScheduleType(key)
            }
            setFormData((prev) => ({ ...prev, [type]: key }))

        } else {
            if (key === 'fixed') {
                setSelectedEmploymentType(key)
            } else {

                setSelectedEmploymentType(key)
            }
            setFormData((prev) => ({ ...prev, [type]: key }))

        }
    }

    const setValues = (index, name, value, field, functionIndex) => {

        let newData = { ...formData }
        if (field !== "dropdown") {
            if (name === "salary_european") {
                newData['employee_function_details'][functionIndex][name] = value
                newData['employee_function_details'][functionIndex]['salary'] = value
                setDisplaySalary((prev) => prev.map((item, i) => {
                    if (i === functionIndex) {
                        return { ...item, salary_european: value };
                    }
                    return item;
                }))
            } else if (name === "experience") {
                newData['employee_function_details'][functionIndex][name] = value
            } else {
                newData[name] = value
            }

        } else {
            if (name === "function_id") {
                newData['employee_function_details'][functionIndex][name] = value.value
                setFunctionName(value)
            } else if (name === "interim_agency_id") {
                newData[name] = value.value
                setSelectedInterimAgency(value)
            }
        }
        setFormData(newData)

    }


    const detectEndDateChange = (date) => {

        if (data.end_date !== formData.end_date) {
            if (wantDimonaUpdate) {
                setIsEndDateChanged(true)
            } else {
                setIsEndDateChanged(false)
                onSave()
            }
        } else {
            onSave()
        }
    }

    const handleSave = () => {
        formData.send_dimona = true
        onSave()
    }

    const handleWarningCancel = () => {
        setIsEndDateChanged(false)
    }

    const handleNoAction = () => {
        formData.send_dimona = false
        onSave()

    }

    const onSave = () => {
        const { employee_function_details, ...contractDetails } = formData
        data = {
            "employee_profile_id": eid,
            "employee_contract_details": contractDetails,
            "employee_function_details": formData?.employee_function_details,
            "interim_contract": isInterimType
        }

        let url = EmployeeContractApiUrl + "/" + contractId
        AXIOS.service(url, "PUT", data)
            .then((result) => {
                if (result?.success) {
                    if (isEndDateChanged) {
                        setIsEndDateChanged(false)
                    }
                    setRefresh(!refresh)
                    setEditStatus(false)
                    setEditFunction(false)
                    setCardNumber("")
                    setDataRefresh(!dataRefresh)
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });

                } else {
                    setErrors(result.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }

    const reset = (index) => {

        let oldData = copy.employee_function_details[index]
        setFormData((prev) => {
            let currentDetails = [...prev.employee_function_details]
            currentDetails[index] = oldData
            setCardNumber(""); setEditFunction(false);
            return {
                ...prev, employee_function_details: currentDetails
            }
        })
        setDisplaySalary((prev) => prev.map((item, i) => {
            if (i === index) {
                return oldData;
            }
            return item;
        }))
    }

    const handleOk = (index) => {
        // formData.employee_function_details
        // functionData = functionData = [...functionData.slice(0, index), formData.employee_function_details[index], ...functionData.slice(0, index)]
        setCardNumber(""); setEditFunction(false);
        setRefresh(!refresh)
    }


    const fetchMinSalary = (id, experience, index) => {
        let data = {
            "employee_type_id": formData?.employee_type_id,
            "employee_subtype": formData?.sub_type,
            "function_title_id": formData?.employee_function_details[index]?.function_id,
            "experience_in_months": formData?.employee_function_details[index]?.experience,
            "employee_profile_id": eid
        }

        AXIOS.service(FetchMinSalaryApisUrl, "POST", data)
            .then((result) => {
                if (result?.success) {
                    setDisplayMinumumSalary((prev) => {
                        let newArr = [...prev]
                        newArr[functionIndex] = result.data.minimumSalary
                        return newArr
                    })
                    setFormData((prev) => {
                        let newData = { ...prev }
                        newData["employee_function_details"][functionIndex]["min_salary"] = result.data.minimumSalary
                        return newData
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        if (functionIndex !== null && functionIndex !== undefined) {
            fetchMinSalary("", "", functionIndex)
        }
    }, [formData.employee_function_details[functionIndex]?.function_id, formData.employee_function_details[functionIndex]?.experience])

    let commonDataFieldsArray = [
        { title: t("EMPLOYEE_TYPE"), name: "employee_type", type: "text", style: "col-md-6 float-left mt-2", disabled: true },
        // { title: t("EMPLOYEE_TYPE"), name: "employee_type", type: "dropdown", options: employeeTypeList, selectedOptions: employeeType, required: true, isDisabled: true, style: "col-md-6 float-left" },
        (isLongTermContract ? { title: t("SUB_TYPE"), name: "sub_type", type: "text", style: "col-md-6 float-left mt-2", disabled: true } : {}),
        { title: t("START_DATE"), name: "start_date", type: "date", disabled: true, style: "col-md-6 float-left  mt-2" },
        // { title: t("END_DATE"), name: "end_date", type: "date", disabled: (data?.end_date) ? true : false, style: "col-md-6 float-left mt-2" },
        { title: t("END_DATE"), name: "end_date", type: "date", disabled: false, style: "col-md-6 float-left mt-2" },
        (isLongTermContract ? { title: t("WEEKLY_CONTRACT_HOURS"), name: "weekly_contract_hours", type: "text", style: "col-md-6 float-left mt-2" } : {}),
        (isLongTermContract ? { title: t("WORK_DAYS_PER_WEEK"), name: "work_days_per_week", type: "text", style: "col-md-6 float-left mt-2" } : {}),
        // { title: t("SCHEDULE_TYPE"), name: "schedule_type", type: "radio", style: "col-md-6 float-left mt-2" },
        (data?.long_term === true ? { title: t("DIMONA_PERIOD_ID"), name: "dimona_period_id", type: "text", style: "col-md-6 float-left mt-2" } : {}),
        isInterimType ? { title: t("INTERIM_AGENCY"), name: 'interim_agency_id', required: true, options: iterimAgenciesList, isMulti: false, selectedOptions: selectedInterimAgency, type: 'dropdown', style: 'col-md-6 float-left mt-2' } : {},

    ]

    const contractHeaders = [
        { title: t("FUNCTIONS"), field: 'function_title' },
        { title: t("SALARY"), field: 'salary_european' },
        { title: t("START_DATE"), field: 'start_date' },
        { title: t("END_DATE"), field: 'end_date' },
    ]





    const viewAction = (data, action) => {

        let { tableData, ...rowdata } = data
        if (action === 'update_function') {
            setUpdateFunctionData('update_function')
            let contractData = {
                "employee_type_id": formData?.employee_type_id,
                "employee_subtype": formData?.sub_type,
            }
            rowdata = { ...rowdata, ...contractData }
            setFunctionFormData(prev => ({ ...prev, ...rowdata }))
        } else if (action === 'deactivate_function') {
            setUpdateFunctionData('deactivate_function')
            setFunctionFormData(rowdata)
        } else if (action === 'delete') {
            // setDeletePopup(true)
            setDeleteUrl(`${DeleteEmployeeFunctionApiUrl}/${data.function_details_id}`)
        }
    }

    const setData = (data) => {
        setFunctionFormData({ ...functionFormData, ...data })
    }


    const FunctionUpdatePopupBody = forwardRef((prop, ref) => {

        let data = {
            "employee_function_id": functionFormData.function_details_id,
            "update_from_date": "",
            "salary_european": functionFormData.salary_european,
            "experience": functionFormData.experience,
        }

        const [formData, setFormData] = useState(data)
        const [useMinSal, setUseMinSal] = useState(false)



        const fetchMinSalary = (experience) => {
            let data = {
                "employee_contract_id": response.id,
                "function_title_id": functionFormData.function_id,
                "experience_in_months": experience,
                "employee_profile_id": eid
            }
            if (experience) {
                AXIOS.service(FetchMinSalaryApisUrl, "POST", data)
                    .then((result) => {
                        if (result?.success) {
                            setFormData((prev) => {
                                let newData = { ...prev }
                                newData["min_salary"] = result.data.minimumSalary
                                if (useMinSal) {
                                    newData["salary_european"] = result.data.minimumSalary
                                }
                                return newData
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            } else {
                setFormData((prev) => {
                    let newData = { ...prev }
                    newData["min_salary"] = experience
                    console.log(newData);

                    return newData
                })
            }

        }

        const changeCheckbox = () => {
            setUseMinSal(!useMinSal)
            if (!useMinSal) {
                setFormData((prev) => {
                    let newData = { ...prev }
                    newData["salary_european"] = newData.min_salary
                    return newData
                })
            } else {
                setFormData((prev) => {
                    let newData = { ...prev }
                    newData["salary_european"] = ""
                    return newData
                })
            }
        }

        useEffect(() => {
            fetchMinSalary(functionFormData.experience)
        }, [])


        const setFunctionValues = (index, name, value) => {

            let newData = { ...formData }

            if (name === "experience") {
                fetchMinSalary(value)
            }
            if (name === 'salary_european') {
                value === newData.min_salary ? setUseMinSal(true) : setUseMinSal(false)
            }
            newData[name] = value
            setFormData(newData)


        }

        useImperativeHandle(ref, () => ({
            getFormData: () => formData,
        }));

        const checkboxList = [{ key: 1, value: "", name: t("USE_MINIMUM") }]

        let otherDataFieldsArray = [
            // { title: t("FUNCTION_NAME"), name: "function_id", type: "dropdown", options: functionList, selectedOptions: functionName, required: true, style: "col-md-6 float-left mt-2" },
            { title: t("MINIMUM_SALARY"), name: "min_salary", type: "text", style: "col-md-6 float-left mt-2 ", disabled: true },
            { title: "", name: "", checkboxList: checkboxList, changeCheckbox: changeCheckbox, checked: useMinSal, type: "checkbox", style: "mt-4 col-md-6 float-left" },
            { title: t("EXPERIENCE_IN_MONTHS"), name: "experience", type: "text", style: "col-md-6 float-left mt-2" },
            { title: t("SALARY"), name: "salary_european", type: "text", style: "col-md-6 float-left mt-2 " },
            { title: t("APPLY_CHANGES_FROM"), name: "update_from_date", type: "date", disabled: false, style: "col-md-6 float-left  mt-2" },
        ]

        return (
            < FormsNew
                view={'filters'}
                data={otherDataFieldsArray}
                SetValues={setFunctionValues}
                formattedData={formData}
                OnSave={onSave}
            >
            </FormsNew >
        )
    })

    const updateFunction = () => {

        if (functionPopupRef.current) {
            const formData = functionPopupRef.current.getFormData(); // Get form data from child
            data = {
                "employee_function_id": formData.employee_function_id,
                "update_from_date": formData.update_from_date,
                "salary": formData.salary_european,
                "experience": formData.experience,
            }

            AXIOS.service(UpdateEmployeeContractFunctionApiUrl, "POST", data)
                .then((result) => {
                    if (result.success) {
                        setDataRefresh(!dataRefresh)
                        setUpdateFunctionData("")
                        toast.success(result.message[0], {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                    } else {
                        setErrors(result.message)
                    }
                })
        }

    };

    const deactivateFunction = () => {

        AXIOS.service(DeactivateEmployeeFunctionApiUrl, "POST", { employee_function_id: functionFormData.function_details_id })
            .then((result) => {
                if (result.success) {
                    setDataRefresh(!dataRefresh)
                    setRefresh(!refresh)
                    setUpdateFunctionData("")
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    setErrors(result.message)
                }
            })

    }

    const deleteApiCall = (id) => {
        AXIOS.service(deleteUrl, "DELETE")
            .then((result) => {
                if (result.success) {
                    setDataRefresh(!dataRefresh)
                    setRefresh(!refresh)
                    // setDeletePopup(false)
                    setDeleteUrl("")
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    setErrors(result.message)
                }
            })
    }


    return (
        <div>
            {updateFunctionData && <CustomPopup
                close
                header
                footer
                size={updateFunctionData === 'update_function' ? "xl" : "s"}
                onHide={() => setUpdateFunctionData("")}
                title={updateFunctionData === 'update_function' ? <span id="text-indii-blue">{t("UPDATE_FUNCTION")}</span> : <span id="text-indii-blue">{t("DEACTIVATE_FUNCTION")}</span>}
                onConfirm={updateFunctionData === 'update_function' ? updateFunction : deactivateFunction}
            >
                {updateFunctionData === 'update_function' ? <FunctionUpdatePopupBody ref={functionPopupRef} /> : <div className="text-center"><h4 className="text-danger">{t("ARE_YOU_SURE_YOU_WANT_TO_DEACTIVATE") + "...?"}</h4></div>}

            </CustomPopup>}
            {errors !== undefined && errors.length !== 0 && <ErrorPopup
                title={t("VALIDATION_ERROR") + ("!")}
                body={(errors)}
                onHide={() => setErrors([])}
            ></ErrorPopup>}
            {deleteUrl && <ModalPopup
                title={t("WARNING_TITLE")}
                body={t('DELETE_CONFIRMATION')}
                onConfirm={() => deleteApiCall()}
                onHide={() => setDeleteUrl("")}
                backdrop='static'
            ></ModalPopup>}
            {isEndDateChanged && <ModalPopup
                title={t("WARNING_TITLE")}
                body={t('DO_YOU_WANT_TO_SEND_DIMONA_UPDATE')}
                onConfirm={() => handleSave()}
                onHide={() => handleWarningCancel()}
                utilityButtonFunction={() => handleNoAction()}
                backdrop='static'
                utilityButtonName={t("NO")}
            ></ModalPopup>}
            {!edit && commonData?.map((val) => {
                return (
                    <div key={val.label} className={"font-weight-bold col-md-12 row m-0 mb-1"}>
                        <label className="col-md-3 mb-1 pr-0 text-secondary">{val.label}:</label>
                        <p className="mb-0 col-md-9">{val.value}</p>
                    </div>
                )
            })}
            {edit && <div className="row">
                <FormsNew
                    data={commonDataFieldsArray}
                    SetValues={setValues}
                    formattedData={formData}
                    OnSave={onSave}
                >
                </FormsNew>
                {isLongTermContract && edit && <div className="col-md-6 ml-4 p-0 d-flex mb-2">
                    <RadioInput
                        title={t("SCHEDULE_TYPE")}
                        radiobuttonsList={scheduleTypeArray}
                        changeCheckbox={onRadioSelect}
                        CustomStyle={'col-md-4'}
                        selectedOption={selectedScheduleType}
                        type={'schedule_type'}
                    ></RadioInput>
                    <RadioInput
                        title={t("EMPLOYEEMENT_TYPE")}
                        radiobuttonsList={employementTypeArray}
                        changeCheckbox={onRadioSelect}
                        CustomStyle={'col-md-4'}
                        selectedOption={selectedEmploymentType}
                        type={'employment_type'}
                    ></RadioInput>
                </div>}
            </div>
            }
            <div className=" col-md-12 d-flex flex-wrap pb-2">
                {!edit && <Table columns={contractHeaders} rows={functionData} permission={true} viewAction={viewAction} tableName={pastContracts?"past_contract_functions":"contract_functions"}></Table>}
                {edit && <div className="float-right col-md-12 mb-2 text-right mt-2">
                    <CustomButton buttonName={t("SAVE")} ActionFunction={() => { isDimonaExists ? detectEndDateChange(formData?.contractDetails?.end_date) : onSave() }}></CustomButton>
                    <CustomButton buttonName={t("CANCEL")} ActionFunction={() => { setCardNumber(""); setToggleOpen("") }}></CustomButton>
                </div>}
            </div >
        </div >
    )
}
