// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3E%3Cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* popup css starts here */
.btn-close {
    --bs-btn-close-color: #000;
    --bs-btn-close-bg: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    --bs-btn-close-opacity: 0.5;
    --bs-btn-close-hover-opacity: 0.75;
    --bs-btn-close-focus-shadow: 0 0 0 0.25rem #0d6efd40;
    --bs-btn-close-focus-opacity: 1;
    --bs-btn-close-disabled-opacity: 0.25;
    --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
    background: #0000 var(--bs-btn-close-bg) center/1em auto no-repeat;
    border: 0;
    border-radius: 0.375rem;
    box-sizing: initial;
    height: 1em;
    opacity: var(--bs-btn-close-opacity);
    padding: 0.25em;
    width: 1em;
}`, "",{"version":3,"sources":["webpack://./src/utilities/popup/popup.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;IACI,0BAA0B;IAC1B,0DAAkV;IAClV,2BAA2B;IAC3B,kCAAkC;IAClC,oDAAoD;IACpD,+BAA+B;IAC/B,qCAAqC;IACrC,uEAAuE;IACvE,kEAAkE;IAClE,SAAS;IACT,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,oCAAoC;IACpC,eAAe;IACf,UAAU;AACd","sourcesContent":["/* popup css starts here */\n.btn-close {\n    --bs-btn-close-color: #000;\n    --bs-btn-close-bg: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E);\n    --bs-btn-close-opacity: 0.5;\n    --bs-btn-close-hover-opacity: 0.75;\n    --bs-btn-close-focus-shadow: 0 0 0 0.25rem #0d6efd40;\n    --bs-btn-close-focus-opacity: 1;\n    --bs-btn-close-disabled-opacity: 0.25;\n    --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);\n    background: #0000 var(--bs-btn-close-bg) center/1em auto no-repeat;\n    border: 0;\n    border-radius: 0.375rem;\n    box-sizing: initial;\n    height: 1em;\n    opacity: var(--bs-btn-close-opacity);\n    padding: 0.25em;\n    width: 1em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
