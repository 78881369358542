import React, { useEffect, useState } from "react";
import DeleteIcon from "../../static/icons/Delete.svg"
import AddIcon from "../../static/icons/AddPlusIcon.png";
import { BASE_URL } from "../../routes/ApiEndPoints";
import { APICALL as AXIOS } from "../../services/AxiosServices"
import TextInput from "../atoms/formFields/TextInput";
import Dropdown from "../atoms/Dropdown";
import { getFormattedDropdownOptions, convertEuropeanToNormal, convertNormalToEuropean } from "../../utilities/CommonFunctions";
import { t } from "../../translations/Translation";
import CustomCheckBox from "../atoms/formFields/CustomCheckBox"
import { FetchMinSalaryApisUrl } from "../../routes/ApiEndPoints";
import { Tooltip as ReactTooltip } from "react-tooltip";

export default function AddEmployeeFunctionSalaries({ tabIndex, options, functionSalaries, setFunctionSalaries, locationTransport, setLocationTransport, functions, setFunctions, locations, setLocations, commute, setCommute, employeeContracts, setEmployeeContracts, checkedStates, setCheckedStates, selectedEmpTypeCategory, eid, employeeData, salaryType, setSalaryType }) {

    const [displayMinumumSalary, setDisplayMaxumumSalary] = useState(true)

    const FunctionSalariesHeaders = [
        { title: t("FUNCTION_TITLE"), style: salaryType === 'flex' ? 'col-md-2 pl-3 font-14' : "col-md-3 pl-3 font-14" },
        { title: t("EXPERIENCE"), style: 'col-md-2 text-center font-14' },
        (displayMinumumSalary ? { title: t("MINIMUM_SALARY"), style: 'col-md-2 text-center font-14' } : {}),
        (displayMinumumSalary ? { title: t("USE_MINIMUM"), style: 'col-md-1 text-center font-14' } : {}),
        { title: t("SALARY_TO_BE_PAID"), style: displayMinumumSalary ? 'col-md-2 text-center font-14' : 'col-md-3 text-center font-14' },
        (salaryType === 'flex' ? { title: t("EXCLUSIVE_SALARY"), style: displayMinumumSalary ? 'col-md-2 text-center font-14' : 'col-md-3 text-center font-14' } : {}),
        { title: t("ACTIONS"), style: displayMinumumSalary ? (salaryType === 'flex' ? 'col-md-1 text-right pr-5 font-14' : 'col-md-2 text-right pr-5 font-14') : 'col-md-3 text-right pr-5 font-14' },
    ];

    const TransportationHeaders = [
        { title: t("LOCATION"), style: 'col-md-3 pl-3' },
        { title: t("DISTANCE_IN_KMS"), style: 'col-md-3 text-center' },
        { title: t("COMMUTE"), style: 'col-md-3 text-center' },
        { title: t("ACTIONS"), style: 'col-md-3 text-right pr-5' },
    ]

    const headers = (tabIndex === 2 ? FunctionSalariesHeaders : TransportationHeaders)
    const [rows, setRows] = useState([1])
    const checkboxList = [{ key: 1, value: "" }]

    const calculateExclusiveSal = (salary) => {
        //here exclusive salary is 7.67% less than actual salary
        //here salary is we are using to calculate is inclusive salary thais 107.67%
        if (convertNormalToEuropean(isNaN(convertEuropeanToNormal(salary)) ? "" : (convertEuropeanToNormal(salary) / 1.0767).toFixed(4)) === "invalid") {
            return ""
        }
        return convertNormalToEuropean(isNaN(convertEuropeanToNormal(salary)) ? "" : (convertEuropeanToNormal(salary) / 1.0767).toFixed(4))

    }

    let GroupedOptions = [
        {
            label: t('COMPANY_FUNCTIONS'),
            options: getFormattedDropdownOptions(options.functions?.company_function_titles, 'id', 'name')
        },
        {
            label: t('OTHER_FUNCTIONS'),
            options: getFormattedDropdownOptions(options.functions?.sector_function_titles, 'id', 'name')
        },
    ]

    const changeCheckbox = (index) => {

        setCheckedStates(prevStates => {
            const newCheckedStates = [...prevStates];
            newCheckedStates[index] = !newCheckedStates[index];
            return newCheckedStates;
        });
        let function_salaries = [...functionSalaries]

        // setIsChecked(!isChecked)
        if (!checkedStates[index]) {

            let updatedSalary = function_salaries[index].min_salary
            if (salaryType === 'flex') {
                function_salaries.map((val) => {
                    val.salary = updatedSalary
                    val.exclusive_salary = calculateExclusiveSal(updatedSalary)
                })

            } else {
                function_salaries[index] = { ...function_salaries[index], salary: updatedSalary }
            }
            setFunctionSalaries(function_salaries)
        } else {

            if (salaryType === 'flex') {
                function_salaries.map((val) => {
                    val.salary = ""
                    val.exclusive_salary = ""
                })
            } else {
                function_salaries[index] = { ...function_salaries[index], salary: "" }
            }
            setFunctionSalaries(function_salaries)
        }
    }


    // Function to add new row in experience/age tab
    const AddNewRow = (type) => {
        const rowsInput = 1;
        // Adding empty object for each row on add row click
        if (tabIndex === 2) {
            const rowData = {
                'function_id': '',
                'min_salary': '',
                'salary': salaryType === 'flex' ? functionSalaries[0].salary : '',
                'experience': '',
            }
            if (salaryType === 'flex') {
                rowData.exclusive_salary = calculateExclusiveSal(functionSalaries[0].salary)
            }
            setFunctionSalaries([...functionSalaries, rowData])

        } else {
            const rowData = {
                'location_id': '',
                'commute_type_id': '',
                'distance': '',
            }
            setLocationTransport([...locationTransport, rowData])

        }
        if (rows !== undefined) {
            setRows([...rows, rowsInput])
        }
    }

    // Function to delete each row in function_salaries/transport tab
    function DeleteRow(index) {
        if (tabIndex === 2) {
            // Remove data from experience data
            const rows = [...functionSalaries];
            rows.splice(index, 1);
            setFunctionSalaries(rows);

        } else {
            // Remove data from age data
            const rows = [...locationTransport];
            rows.splice(index, 1);
            setLocationTransport(rows);
        }
        const data = [...rows];
        data.splice(index, 1);
        setRows(data);
    }

    const fetchMinSalary = (id, experience, index) => {
        let data = {
            "employee_type_id": employeeContracts.employee_type_id,
            "employee_subtype": employeeContracts["sub_type"],
            "function_title_id": id,
            "experience_in_months": experience,
        }
        if (eid) {
            data.employee_profile_id = eid
        } else {
            data.date_of_birth = employeeData.date_of_birth
        }

        AXIOS.service(FetchMinSalaryApisUrl, "POST", data)
            .then((result) => {
                if (result?.success) {
                    let response = result.data.minimumSalary
                    let function_salaries = [...functionSalaries]
                    let row = functionSalaries[index]
                    row["min_salary"] = response
                    function_salaries[index] = row
                    setFunctionSalaries(function_salaries)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    useEffect(() => {
        const employeeTypes = options?.employee_contract_options?.employee_types
        let emptype = []
        if (employeeTypes && selectedEmpTypeCategory !== undefined) {
            emptype = employeeTypes[selectedEmpTypeCategory]?.filter((item) => item.key == employeeContracts['employee_type_id'])
            setDisplayMaxumumSalary(emptype[0]?.minimum_salary)
            setSalaryType(emptype[0]?.salary_type)
        }
        if (employeeContracts !== undefined && employeeContracts["employee_type_id"] !== "" && employeeContracts["sub_type"] !== "" && tabIndex === 2 && emptype[0]?.minimum_salary) {
            functionSalaries.map((obj, index) => {

                fetchMinSalary(obj["function_id"], obj["experience"], index)

            })
        }
        if (tabIndex === 2) {
            setRows(functionSalaries)
            setDisplayMaxumumSalary(emptype[0]?.minimum_salary)
        } else if (tabIndex === 3) {
            setRows(locationTransport)
        }
    }, [])

    const SetValues = (value, type, index) => {
        const index_data = tabIndex === 2 ? [...functionSalaries] : [...locationTransport]
        if (type === 'function') {
            const data = [...functions]
            data[index] = value
            setFunctions(data)

            index_data[index]['function_id'] = value?.value ? value.value : ""
            setFunctionSalaries(index_data)
            if (displayMinumumSalary) {
                fetchMinSalary(functionSalaries[index]["function_id"], functionSalaries[index]["experience"], index)
            }
        } else if (type === 'salary') {
            if (salaryType === "flex") {
                index_data.map((val) => val.salary = value)
                index_data.map((val) => val.exclusive_salary = calculateExclusiveSal(value))
            } else {
                index_data[index]['salary'] = value
            }
            setFunctionSalaries(index_data)
            setCheckedStates(prevStates => {
                const newCheckedStates = [...prevStates];
                newCheckedStates[index] = functionSalaries[index]['min_salary'] !== value ? false : true
                return newCheckedStates;
            });
        } else if (type === 'experience') {
            index_data[index]['experience'] = value
            setFunctionSalaries(index_data)
            fetchMinSalary(functionSalaries[index]["function_id"], functionSalaries[index]["experience"], index)
        } else if (type === 'location') {
            const data = [...locations]
            data[index] = value
            setLocations(data)

            index_data[index]['location_id'] = value.value
            setLocationTransport(index_data)
        } else if (type === 'commute') {
            const data = [...commute]
            data[index] = value
            setCommute(data)

            index_data[index]['commute_type_id'] = value.value
            setLocationTransport(index_data)
        } else if (type === 'distance') {
            index_data[index]['distance'] = value
            setLocationTransport(index_data)
        }


    }


    return (
        <div className="d-flex p-3">
            <div className="col-md-12 px-0 border">
                <div className="row col-md-12 table-head-bg p-2 m-0">
                    {headers.map((head, i) => {
                        return (
                            <div className={head.style} key={head.title}>
                                <p className="mb-0 font-weight-bold">{head.title}</p>
                            </div>
                        )
                    })}
                </div>

                {rows.map((val, index) => {
                    return (
                        <div className="row col-md-12 p-3 m-0 border-bottom" key={index}>
                            <div className={salaryType === 'flex' ? "col-md-2 pl-0" : "col-md-3 pl-0"}>
                                <Dropdown
                                    options={tabIndex === 2 ? GroupedOptions : getFormattedDropdownOptions(options.locations, 'id', 'location_name')}
                                    selectedOptions={tabIndex === 2 ? functions[index] : locations[index]}
                                    onSelectFunction={(e) => SetValues(e, (tabIndex === 2 ? 'function' : 'location'), index)}
                                    CustomStyle="col-md-12 pl-0 float-left"
                                    title={''}
                                    required={false}
                                    isMulti={false}
                                    error={''}
                                    styleMargin={''}
                                    info
                                    styleClass="col-12 pl-0 pr-0"
                                    isClearable={true}
                                ></Dropdown>
                            </div>
                            {tabIndex === 2 && <div className="col-md-2">
                                <div className="row m-0 justify-content-center">
                                    <TextInput
                                        key={'experience' + val}
                                        title={''}
                                        name={'experience'}
                                        CustomStyle="col-md-12 float-left"
                                        required={false}
                                        value={functionSalaries[index]['experience']}
                                        setValue={(e) => SetValues(e, 'experience', index)}
                                    // error={''}
                                    ></TextInput>
                                </div>
                            </div>}
                            {tabIndex === 2 && displayMinumumSalary && <div className={"col-md-2"}>
                                <div className="row m-0 justify-content-center">
                                    <TextInput
                                        key={'min_salary' + val}
                                        title={''}
                                        name={'min_salary'}
                                        CustomStyle={"col-md-12 float-left"}
                                        required={false}
                                        value={functionSalaries[index]['min_salary']}
                                        setValue={(e) => SetValues(e, 'min_salary', index)}
                                        disabled={true}
                                    // error={''}
                                    ></TextInput>
                                </div>
                            </div>}
                            {tabIndex === 2 && displayMinumumSalary && <div className="col-md-1">
                                <div className="row m-0 justify-content-center">
                                    <CustomCheckBox
                                        key={'min'}
                                        title={''}
                                        checkboxList={checkboxList}
                                        changeCheckbox={() => changeCheckbox(index)}
                                        CustomStyle={'col-md-2 float-left'}
                                        checked={checkedStates[index]}
                                        disable={salaryType === "flex" && index > 0 ? true : false}
                                    ></CustomCheckBox>
                                </div></div>}
                            {tabIndex === 2 && <div className={displayMinumumSalary ? "col-md-2" : "col-md-4"}>
                                <div className="row m-0 justify-content-center">
                                    <TextInput
                                        key={'function' + val}
                                        title={''}
                                        name={'salary'}
                                        CustomStyle={"col-md-12 float-left"}
                                        required={false}
                                        value={functionSalaries[index]['salary']}
                                        setValue={(e) => SetValues(e, 'salary', index)}
                                        disabled={salaryType === "flex" && index > 0 ? true : false}
                                    // error={''}
                                    ></TextInput>
                                </div>
                            </div>}
                            {tabIndex === 2 && salaryType === "flex" && <div className={displayMinumumSalary ? "col-md-2" : "col-md-4"}>
                                <div className="row m-0 justify-content-center">
                                    <TextInput
                                        key={'function' + val}
                                        title={''}
                                        name={'exclusive_salary'}
                                        CustomStyle={"col-md-12 float-left"}
                                        required={false}
                                        value={functionSalaries[index]['exclusive_salary']}
                                        setValue={(e) => SetValues(e, 'exclusive_salary', index)}
                                        disabled={true}
                                    // error={''}
                                    ></TextInput>
                                </div>
                            </div>}
                            {tabIndex === 3 && <div className="col-md-3">
                                <div className="row m-0 justify-content-center">
                                    <TextInput
                                        key={'location' + val}
                                        title={''}
                                        name={'distance'}
                                        CustomStyle="col-md-8 float-left"
                                        required={false}
                                        value={locationTransport[index]['distance']}
                                        setValue={(e) => SetValues(e, 'distance', index)}
                                    // error={''}
                                    ></TextInput>
                                </div>
                            </div>}
                            {tabIndex === 3 && <div className="col-md-3">
                                <div className="row m-0 justify-content-center">
                                    <Dropdown
                                        options={getFormattedDropdownOptions(options.commute_types)}
                                        selectedOptions={commute[index]}
                                        onSelectFunction={(e) => SetValues(e, 'commute', index)}
                                        CustomStyle="col-md-12 pl-0 float-left"
                                        title={''}
                                        required={false}
                                        isMulti={false}
                                        error={''}
                                        styleMargin={''}
                                    ></Dropdown>
                                </div>
                            </div>}

                            {index === rows.length - 1 && <div className={tabIndex === 3 ? "col-md-3 text-right pr-4" : (tabIndex === 2 && salaryType === "flex" ? "col-md-1 text-right pr-4" : "col-md-2 text-right pr-4")}>
                                {((tabIndex === 2 && selectedEmpTypeCategory !== 1) || tabIndex === 3) && <><img className="header-icon mr-4 pointer" data-tooltip-id={t("ADD_TEXT")} alt={t("ADD_TEXT")} src={AddIcon} onClick={() => AddNewRow()}></img>
                                    <ReactTooltip
                                        id={t("ADD_TEXT")}
                                        place="top"
                                        globalEventOff="click"
                                        content={t("ADD_TEXT")}
                                    />
                                </>}
                                {rows.length > 1 && <><img className="header-icon pointer" data-tooltip-id={t("DELETE")} alt={t("DELETE")} src={DeleteIcon} onClick={() => DeleteRow(index)}></img>
                                    <ReactTooltip
                                        id={t("DELETE")}
                                        place="top"
                                        globalEventOff="click"
                                        content={t("DELETE")}
                                    />
                                </>}
                            </div>}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
