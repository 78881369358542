import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import Companies from '../pages/Companies';
import Settings from '../pages/Settings';

import Login from '../pages/Login';
import Employees from '../pages/Employees';
import Configurations from '../pages/Configurations';
import ConfigurationOverviews from '../components/organisms/ConfigurationOverviews';
import AddFunction from '../components/organisms/AddFunction';
import AddEmployeeTypes from '../components/organisms/AddEmployeeTypes';
import AddInterimAgency from '../components/organisms/AddInterimAgency';
import AddSector from '../components/organisms/AddSector';
import AddGroupFunction from '../components/organisms/AddGroupFunction';
import AddContractType from '../components/organisms/AddContractType';
import CompanyCreation from '../components/organisms/CompanyCreation';
import HolidayCodeCreation from '../components/molecules/HolidayCodeCreation';
import MyAccount from "../pages/MyAccount";
import EmployeeCreation from '../components/organisms/EmployeeCreation';
import HolidayCodeConfigurationOverview from '../components/organisms/HolidayCodeConfigurationOverview';
import CommunicationConfigurationOverview from '../components/organisms/CommunicationConfigurationOverview';
// const langRegex = "(en|nl|fr)?";
// const langPath =  `/:lang${langRegex}`;
import AddReasons from '../components/organisms/AddReasons';
import AddSocialSecretary from '../components/organisms/AddSocialSecretary';
import LinkHolidayCodeToSocialSecretary from "../components/molecules/LinkHolidayCodeToSocialSecretary"
import Planning from '../pages/Planning';
import AddEmailTemplate from '../components/organisms/AddEmailTemplate';
import AddContractsTemplate from '../components/organisms/AddContractsTemplate'
import Uurrooster from '../pages/Uurrooster';
import ClonePlanning from '../components/molecules/ClonePlanning';
import OthPlanning from '../components/organisms/OthPlanning';
import AddOthPlans from '../components/organisms/AddOthPlans';
import ViewOpenShiftDetails from '../components/molecules/ViewOpenShiftDetails';
import AddPublicHoliday from '../components/molecules/AddPublicHoliday';
import EmployeeBasicDetails from '../components/organisms/EmployeeBasicDetails';
import SocialSecretaryAndReportingConfigurationOverview from "../components/organisms/SocialSecretaryAndReportingConfigurationOverview"
import AddTaxes from "../components/molecules/AddTaxes"
import MonthlyOverview from '../pages/MonthlyOverview';
import AddSalaryCoefficient from '../components/molecules/AddSalaryCoefficient';
import ImportStatusOverView from '../components/molecules/ImportStatusOverView';
import AddOvertime from '../components/molecules/AddOvertime';
import AddFlexSalary from '../components/molecules/AddFlexSalary';
import AddEventPlanning from '../components/molecules/AddEventPlanning';
import ResetForgotPassword from '../components/molecules/ResetForgotPassword';
import MonthlyEmployeeOverview from '../components/organisms/Reporting/MonthlyEmployeeOverview';
import FaqOverview from "../components/molecules/FaqOverview"
import AddSocialSecretaryEngine from '../components/organisms/AddSocialSecretaryEngine';
import EventEmployeeList from '../components/molecules/EventEmployeeList';
import AddWorkCode from '../components/molecules/AddWorkCode';
import InvitedEmployeeOverview from '../components/molecules/InvitedEmployeesOverview';
import AddRole from '../components/molecules/AddRole';
import RolePermissions from '../components/molecules/RolePermissions';
import CurrentPlan from '../pages/CurrentPlan';
import ManageCompanyGroups from '../components/molecules/ManageCompanyGroup';
import AddAdmin from '../components/organisms/AddAdmin';
import AddNotifiction from '../components/organisms/AddNotifications';
import EmployeeCompanyDetails from '../components/molecules/EmployeeCompanyDetails';
import { InvoiceConfigurationOverview } from '../components/organisms/InvoiceConfigurationOverview';
import { AddNotificationTemplate } from '../components/organisms/AddNotificationTemplate';
import AddNightHourCost from '../components/molecules/AddNightHourCost';
import { CheckPermissionAndReturnComponet } from '../utilities/CommonFunctions';
import PageNotFound from '../components/molecules/PageNotFound';

const BaseRouter = ({ setAuth, setCompany }) => (
    <Routes>
        {/* route starts here */}
        {/* <Route exact path={active language}  element={<Component name />} /> */}
        <Route exact path="/" element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin', 'moderator', 'employees', 'planning', 'add_employee', 'dimona_overview', 'timetable', 'download_qr_code', 'activate_device', 'employee_availability', 'holiday_overview', 'invite_employee']}><Dashboard></Dashboard></CheckPermissionAndReturnComponet>} />
        <Route exact path="/login" element={<Login></Login>} />

        <Route exact path="/manage-employees" element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin', 'moderator', 'employees', 'employee_availability']}><Employees></Employees></CheckPermissionAndReturnComponet>} />
        <Route exact path="/manage-employees/:id" element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin', 'employees', 'employee_availability']}><Employees></Employees></CheckPermissionAndReturnComponet>} />
        <Route exact path="/add-employees" element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin', 'add_employee']}><EmployeeCreation></EmployeeCreation></CheckPermissionAndReturnComponet>} />
        <Route exact path="/add-employees/:id" element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin', 'add_employee']}><EmployeeCreation></EmployeeCreation></CheckPermissionAndReturnComponet>} />

        <Route exact path="/manage-companies" element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin', 'companies']}><Companies setCompany={setCompany}></Companies></CheckPermissionAndReturnComponet>} />
        <Route exact path="/manage-companies/:addType/:id" element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin', 'companies','create_company']}><CompanyCreation setCompany={setCompany} /></CheckPermissionAndReturnComponet>} />

        <Route exact path='/configurations' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin', 'configuration']}><Configurations></Configurations></CheckPermissionAndReturnComponet>} />
        <Route exact path='/manage-configurations/:type' element={<CheckPermissionAndReturnComponet requiredPermissions={(type) => {

            //***here match keys used should be the type used 
            const permissionMapping = {
                "reasons": ["superadmin"],
                "sectors": ["superadmin"],
                "contract_type": ["superadmin"],
                "employee_type": ["superadmin"],
                "functions": ["superadmin"],
                "group_functions": ["superadmin"],
                "min_salary": ["superadmin"],
                'social_secretary': ["superadmin"],
                'interim-agencies': ["superadmin"],
                "default_param": ["superadmin"],
                "parameters": ["superadmin"],
                "social_secretary_engines": ["superadmin"],
                "roles": ["superadmin"],
                "manage_admins": ["superadmin"],
                "notifications": ["superadmin", "moderator"],
                "manage_employees": ["superadmin", "moderator"],
            }
            return permissionMapping[type];
        }} type="reasons"><ConfigurationOverviews></ConfigurationOverviews></CheckPermissionAndReturnComponet>} />
        {/* <Route exact path='/manage-configurations/:type/:id' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><ConfigurationOverviews></ConfigurationOverviews></CheckPermissionAndReturnComponet>} /> */}

        <Route exact path='/add-function' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddFunction></AddFunction></CheckPermissionAndReturnComponet>} />
        <Route exact path='/add-function/:id' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddFunction></AddFunction></CheckPermissionAndReturnComponet>} />

        <Route exact path='/add-contract-type' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddContractType></AddContractType></CheckPermissionAndReturnComponet>} />
        <Route exact path='/add-contract-type/:id' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddContractType></AddContractType></CheckPermissionAndReturnComponet>} />

        <Route exact path='/add-employee-type' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddEmployeeTypes></AddEmployeeTypes></CheckPermissionAndReturnComponet>} />
        <Route exact path='/add-employee-type/:id' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddEmployeeTypes></AddEmployeeTypes></CheckPermissionAndReturnComponet>} />
        <Route exact path='/add-interim-agency' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddInterimAgency></AddInterimAgency></CheckPermissionAndReturnComponet>} />
        <Route exact path='/add-interim-agency/:id' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddInterimAgency></AddInterimAgency></CheckPermissionAndReturnComponet>} />

        <Route exact path='/add-sector' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddSector></AddSector></CheckPermissionAndReturnComponet>} />
        <Route exact path='/add-sector/:id' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddSector></AddSector></CheckPermissionAndReturnComponet>} />

        <Route exact path='/add-group-function' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddGroupFunction></AddGroupFunction></CheckPermissionAndReturnComponet>} />
        <Route exact path='/add-group-function/:id' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddGroupFunction></AddGroupFunction></CheckPermissionAndReturnComponet>} />

        <Route exact path='/add-holiday-code' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><HolidayCodeCreation></HolidayCodeCreation></CheckPermissionAndReturnComponet>} />
        <Route exact path='/add-holiday-code/:id' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><HolidayCodeCreation></HolidayCodeCreation></CheckPermissionAndReturnComponet>} />

        <Route exact path='/my-account' element={<MyAccount setAuth={setAuth}></MyAccount>} />
        <Route exact path='/settings' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin', 'settings']}><Settings></Settings></CheckPermissionAndReturnComponet>} />
        <Route exact path='/manage-holiday-configurations/:type' element={<CheckPermissionAndReturnComponet requiredPermissions={(type) => {

            const permissionMapping = {
                "holiday_code": ['superadmin'],
                "holiday_code_configuration": ['superadmin'],
                "public_holiday_configuration": ['superadmin'],
            }
            return permissionMapping[type];

        }}><HolidayCodeConfigurationOverview></HolidayCodeConfigurationOverview></CheckPermissionAndReturnComponet>} />

        <Route exact path='/add-reasons' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddReasons></AddReasons></CheckPermissionAndReturnComponet>} />
        <Route exact path='/add-reasons/:id' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddReasons></AddReasons></CheckPermissionAndReturnComponet>} />

        <Route exact path='/add-social-secretary' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddSocialSecretary></AddSocialSecretary></CheckPermissionAndReturnComponet>} />
        <Route exact path='/add-social-secretary/:id' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddSocialSecretary></AddSocialSecretary></CheckPermissionAndReturnComponet>} />

        <Route exact path='/add-social-secretary-engine' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddSocialSecretaryEngine></AddSocialSecretaryEngine></CheckPermissionAndReturnComponet>} />
        <Route exact path='/add-social-secretary-engine/:id' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddSocialSecretaryEngine></AddSocialSecretaryEngine></CheckPermissionAndReturnComponet>} />

        <Route exact path='/link-holiday-code/:id' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><LinkHolidayCodeToSocialSecretary></LinkHolidayCodeToSocialSecretary></CheckPermissionAndReturnComponet>} />


        <Route exact path="/manage-plannings" element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin', 'planning', 'planning_overview', 'modify_planning', 'dimona_overview', 'holiday_overview']}><Planning setHeaderCompanyDropdown={setCompany}></Planning></CheckPermissionAndReturnComponet>} />
        <Route exact path="/manage-plannings/:type/:id" element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin', 'open_shifts_overview', 'modify_open_shifts', 'dimona_overview', 'holiday_overview']}><ViewOpenShiftDetails></ViewOpenShiftDetails></CheckPermissionAndReturnComponet>} />
        <Route exact path="/clone-plannings/:id" element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin', 'clone_planning']}><ClonePlanning></ClonePlanning></CheckPermissionAndReturnComponet>} />

        <Route exact path='/add-email-template' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddEmailTemplate></AddEmailTemplate></CheckPermissionAndReturnComponet>} />
        <Route exact path='/add-email-template/:id' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddEmailTemplate></AddEmailTemplate></CheckPermissionAndReturnComponet>} />

        <Route exact path='/manage-communication-configurations/:type' element={<CheckPermissionAndReturnComponet requiredPermissions={(type) => {
            const permissionMapping = {
                'email': ['superadmin'],
                'translation': ['superadmin', 'moderator'],
                'contracts_template': ['superadmin'],
                'notification_template': ['superadmin'],
                'faq': ['superadmin', 'moderator'],
            }
            return permissionMapping[type];
        }}><CommunicationConfigurationOverview></CommunicationConfigurationOverview></CheckPermissionAndReturnComponet>} />

        <Route exact path='/add-contracts-template/:addType' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddContractsTemplate></AddContractsTemplate></CheckPermissionAndReturnComponet>} />
        <Route exact path='/add-contracts-template/:addType/:id' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddContractsTemplate></AddContractsTemplate></CheckPermissionAndReturnComponet>} />

        <Route exact path='/uurrooster' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin', 'timetable', 'uurrooster_employee_cost']}><Uurrooster></Uurrooster></CheckPermissionAndReturnComponet>} />

        <Route exact path='/oth-planning/:eid' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin', 'oth_planning_overview', 'modify_oth_planning']}><OthPlanning></OthPlanning></CheckPermissionAndReturnComponet>} />
        <Route exact path='/create-oth-plans/:eid' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin', 'oth_planning_overview']}><AddOthPlans></AddOthPlans></CheckPermissionAndReturnComponet>} />
        <Route exact path='/update-oth-plans/:eid/:id' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin', 'modify_oth_planning']}><AddOthPlans></AddOthPlans></CheckPermissionAndReturnComponet>} />

        <Route exact path='/add-public-holiday' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddPublicHoliday></AddPublicHoliday></CheckPermissionAndReturnComponet>} />
        <Route exact path='/add-public-holiday/:id' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddPublicHoliday></AddPublicHoliday></CheckPermissionAndReturnComponet>} />

        <Route exact path='/manage-social-secretary-and-reporting-configurations/:type' element={<CheckPermissionAndReturnComponet requiredPermissions={(type) => {
            const permissionMapping = {
                "taxes": ['superadmin'],
                "salary_coefficient": ['superadmin'],
                "workcodes": ["superadmin"],
                "night_hour_cost": ['superadmin'],
            }
            return permissionMapping[type];
        }}><SocialSecretaryAndReportingConfigurationOverview></SocialSecretaryAndReportingConfigurationOverview></CheckPermissionAndReturnComponet>} />
        <Route exact path='/add-taxes' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddTaxes></AddTaxes></CheckPermissionAndReturnComponet>} />
        <Route exact path='/add-taxes/:id' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddTaxes></AddTaxes></CheckPermissionAndReturnComponet>} />
        <Route exact path='/add-salary-coefficient' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddSalaryCoefficient></AddSalaryCoefficient></CheckPermissionAndReturnComponet>} />
        <Route exact path='/add-salary-coefficient/:id' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddSalaryCoefficient></AddSalaryCoefficient></CheckPermissionAndReturnComponet>} />
        <Route exact path='/add-overtime' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddOvertime></AddOvertime></CheckPermissionAndReturnComponet>} />
        <Route exact path='/add-overtime/:id' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddOvertime></AddOvertime></CheckPermissionAndReturnComponet>} />

        <Route exact path='/add-workcode' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddWorkCode></AddWorkCode></CheckPermissionAndReturnComponet>} />
        <Route exact path='/add-workcode/:id' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddWorkCode></AddWorkCode></CheckPermissionAndReturnComponet>} />

        <Route exact path='/import-employees' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin', 'employees']}><ImportStatusOverView></ImportStatusOverView></CheckPermissionAndReturnComponet>} />

        <Route exact path='/monthly-overview' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin', 'monthly_overview']}><MonthlyOverview></MonthlyOverview></CheckPermissionAndReturnComponet>} />
        <Route exact path='/monthly-overview/:id' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin', 'monthly_overview']}><MonthlyEmployeeOverview></MonthlyEmployeeOverview></CheckPermissionAndReturnComponet>} />

        <Route exact path='/add-flex-salary' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddFlexSalary></AddFlexSalary></CheckPermissionAndReturnComponet>} />
        <Route exact path='/add-event' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddEventPlanning></AddEventPlanning></CheckPermissionAndReturnComponet>} />
        <Route exact path='/event-employees' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><EventEmployeeList></EventEmployeeList></CheckPermissionAndReturnComponet>} />

        <Route exact path='/faq-overview' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin', 'moderator']}><FaqOverview></FaqOverview></CheckPermissionAndReturnComponet>} />
        <Route exact path='/faq' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin', 'moderator']}><FaqOverview></FaqOverview></CheckPermissionAndReturnComponet>} />
        <Route exact path='/manage-invites' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin', 'invite_employee']}><InvitedEmployeeOverview></InvitedEmployeeOverview></CheckPermissionAndReturnComponet>} />
        <Route exact path='/add-role/:id?' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddRole></AddRole></CheckPermissionAndReturnComponet>} />
        <Route exact path='/role-permissions/:id' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><RolePermissions></RolePermissions></CheckPermissionAndReturnComponet>} />
        <Route exact path='/current-plan' element={<CheckPermissionAndReturnComponet requiredPermissions={['current_plan']}><CurrentPlan setAuth={setAuth} /></CheckPermissionAndReturnComponet>} />
        <Route exact path='/manage-company-groups' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin', 'moderator']}><ManageCompanyGroups></ManageCompanyGroups></CheckPermissionAndReturnComponet>} />
        <Route exact path='/add-admin' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddAdmin></AddAdmin></CheckPermissionAndReturnComponet>} />
        <Route exact path='/add-admin/:id' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddAdmin></AddAdmin></CheckPermissionAndReturnComponet>} />
        <Route exact path='/employee-company-details/:id' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin', 'moderator']}><EmployeeCompanyDetails /></CheckPermissionAndReturnComponet>} />

        <Route exact path='/notifications-form' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin', 'moderator']}><AddNotifiction></AddNotifiction></CheckPermissionAndReturnComponet>} />
        <Route exact path='/configurations/invoice-configurations/:type' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><InvoiceConfigurationOverview></InvoiceConfigurationOverview></CheckPermissionAndReturnComponet>} />
        <Route exact path='/configurations/add-notification-template/:id' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddNotificationTemplate></AddNotificationTemplate></CheckPermissionAndReturnComponet>} />
        <Route exact path='/configurations/add-nighthour-cost' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddNightHourCost></AddNightHourCost></CheckPermissionAndReturnComponet>} />
        <Route exact path='/configurations/add-nighthour-cost/:id' element={<CheckPermissionAndReturnComponet requiredPermissions={['superadmin']}><AddNightHourCost></AddNightHourCost></CheckPermissionAndReturnComponet>} />
        <Route path='/*' element={<PageNotFound />} />

    </Routes>
)

const InviteRoute = () => (
    <Routes>
        <Route exact path='/employee-invitations/:validtoken' element={<EmployeeBasicDetails></EmployeeBasicDetails>} />
    </Routes>
)

const ResetPasswordRoute = () => (
    <Routes>
        <Route exact path='/reset-password/:validtoken' element={<ResetForgotPassword></ResetForgotPassword>} />
    </Routes>
)

export default BaseRouter;
export { InviteRoute, ResetPasswordRoute }